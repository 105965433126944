import React from "react";
import classnames from "classnames";
import { realtor } from "../Admin/lib/defaults";

import withSurvey from "../withSurvey";

import CompactView from "./components/CompactView";
import FullView from "./components/FullView";

import styles from "./styles.module.less";
import heroes from "./heroes.module.less";

const Profile = ({
    templateKey,
    branch = {},
    slug,
    applyNow = "",
    applyNowScript = "",
    logIn = "",
    jobTitle,
    name,
    contactInfo = {},
    modules = [],
    team = {},
    licenseIds = [],
    socialIcons = [],
    gallery = [],
    stars,
    ratingsCount,
    isReviewInit,
    shouldDisplaySurvey,
    graphic,
    showCompactView,
    title,
    body,
    realtor,
    backgroundImage,
    backgroundImageCustom = "",
    photoAlignment = "center",
    darkText = true,
    searchVisibility = [],
    overrideAddress = false,
    overrideAddressText = "",
    licensedInText = "",
    licenseLink,
}) => {
    const {
        photo = "https://ucarecdn.com/b2aaa0cb-d242-48d7-ac87-6cc18f55d6ba/-/preview/200x200/be-circle-mark.png",
        image1 = "",
        image2 = "",
        image3 = "",
    } = gallery || {};
    let imageList = [photo, image1, image2, image3];

    let profileClass = darkText ? styles.darkText : styles.lightText;

    let cls = styles.Profile;
    let style = {};

    if (backgroundImage || backgroundImageCustom) {
        cls = classnames(styles.Profile, {
            [heroes[backgroundImage]]: !backgroundImageCustom,
        });
        style = {
            backgroundPosition: photoAlignment,
        };
        if (!!backgroundImageCustom) {
            style.backgroundImage = `url("${backgroundImageCustom}")`;
        }
    }

    return (
        <section
            className={cls}
            style={style}
            data-background="dark"
            id="profile">
            <div className={styles.inner}>
                <div className={profileClass}>
                    {showCompactView ? (
                        <CompactView
                            templateKey={templateKey}
                            branch={branch}
                            slug={slug}
                            applyNow={applyNow}
                            applyNowScript={applyNowScript}
                            logIn={logIn}
                            jobTitle={jobTitle}
                            name={name}
                            contactInfo={contactInfo}
                            title={title}
                            body={body}
                            modules={modules}
                            team={team}
                            licenseIds={licenseIds}
                            socialIcons={socialIcons}
                            stars={stars}
                            ratingsCount={ratingsCount}
                            isReviewInit={isReviewInit}
                            shouldDisplaySurvey={shouldDisplaySurvey}
                            graphic={graphic}
                            imageList={imageList}
                            darkText={darkText}
                            realtor={realtor}
                            searchVisibility={searchVisibility}
                            overrideAddress={overrideAddress}
                            overrideAddressText={overrideAddressText}
                            licensedInText={licensedInText}
                            licenseLink={licenseLink}
                        />
                    ) : (
                        <FullView
                            templateKey={templateKey}
                            branch={branch}
                            slug={slug}
                            applyNow={applyNow}
                            applyNowScript={applyNowScript}
                            logIn={logIn}
                            jobTitle={jobTitle}
                            name={name}
                            contactInfo={contactInfo}
                            title={title}
                            body={body}
                            modules={modules}
                            team={team}
                            licenseIds={licenseIds}
                            socialIcons={socialIcons}
                            stars={stars}
                            ratingsCount={ratingsCount}
                            isReviewInit={isReviewInit}
                            shouldDisplaySurvey={shouldDisplaySurvey}
                            graphic={graphic}
                            imageList={imageList}
                            darkText={darkText}
                            realtor={realtor}
                            searchVisibility={searchVisibility}
                            overrideAddress={overrideAddress}
                            overrideAddressText={overrideAddressText}
                            licensedInText={licensedInText}
                            licenseLink={licenseLink}
                        />
                    )}
                </div>
            </div>
        </section>
    );
};

export default withSurvey(Profile);
