import React from "react";

import styles from "./styles.module.less";

const Slide = ({ photo, alt }) => {
    if (photo.includes("b2aaa0cb-d242-48d7-ac87-6cc18f55d6ba")) {
        // show logo
        return (
            <div className={styles.LogoContainer}>
                <picture className={styles.Logo}>
                    <img data-blink-src={photo} alt="" />
                </picture>
            </div>
        );
    } else {
        // show portrait
        return (
            <picture className={styles.Portrait}>
                <img data-blink-src={photo} alt="" />
            </picture>
        );
    }
};

export default Slide;
