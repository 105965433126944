export default async function(socialId) {
    try {
        const url = `/.netlify/functions/experience?agent_email=${socialId}`;
        const response = await fetch(url);
        if (!response.ok) {
            throw Error("couldn't fetch");
        }
        return response.json();
    } catch (error) {
        return { ratings: {}, testimonials: [], error };
    }
}
