import React, { Component } from "react";
import styles from "./styles.module.less";

import BEbubble from "./BEEmptyBubble.png";
import Xbutton from "./xbutton.png";
import BEbug from "../../../images/bayequity_circle_mark.png";

class Popup extends Component {
    render() {
        const { title, address, handleClick } = this.props;
        return (
            <div className={styles.container}>
                <div className={styles.relative}>
                    <img src={BEbubble} alt={title} className={styles.bubble} />
                    <img
                        onClick={handleClick}
                        src={Xbutton}
                        alt="xbutton"
                        className={styles.xbutton}
                    />
                    <img
                        src={BEbug}
                        alt="bebug"
                        className={styles.bebug}
                        width="50"
                        height="50"
                    />
                    <div className={styles.address}>
                        <a
                            href={`https://www.google.com/maps?q=bay%20equity,%20${address}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            <div className={styles.titleName}>{title}</div>
                            <div className={styles.addressParagraph}>
                                {address[0]}
                                <br />
                                {address[1] && (
                                    <span>
                                        {address[1]}
                                        <br />
                                    </span>
                                )}
                                {address[2] && (
                                    <span>
                                        {address[2]}
                                        <br />
                                    </span>
                                )}
                                {address[3]}, &nbsp;
                                {address[4]} &nbsp;
                                {address[5]}
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
export default Popup;
