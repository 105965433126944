import React from "react";

import VideoGrid from "./components/VideoGrid";
import VideoSingle from "./components/VideoSingle";

const Video = ({ videos = [], backgroundImageCustom, darkTitleText }) => {
    if (videos.length === 1)
        return (
            <VideoSingle
                URL={videos[0].videoUrl}
                title={videos[0].videoTitle}
                caption={videos[0].videoCaption}
                backgroundImageCustom={backgroundImageCustom}
                darkTitleText={darkTitleText}
            />
        );
    else if (videos.length > 1)
        return (
            <VideoGrid
                videos={videos}
                backgroundImageCustom={backgroundImageCustom}
                darkTitleText={darkTitleText}
            />
        );
    else return null;
};
export default Video;
