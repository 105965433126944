import React, { Component } from "react";

import LinkButton from "../LinkButton";
import Accent from "../Accent";

import { isDate } from "lodash";

import styles from "./styles.module.less";

const defaultPhoto =
    "https://ucarecdn.com/a68e7461-9331-44fa-a034-f3dc2eec7385/-/preview/600x400/alnik383735unsplash1.jpg";

const showEvent = event => {
    const { eventName, eventLocation, eventDescription } = event;
    return eventName || eventLocation || eventDescription;
};

class FutureEvents extends Component {
    render() {
        const { event1, event2, event3 } = this.props;
        if (!showEvent(event1) && !showEvent(event2) && !showEvent(event3)) {
            return null;
        }
        return (
            <section className={styles.FutureEvents} data-background="light">
                <div className={styles.inner}>
                    <h1 className={styles.title}>Upcoming events</h1>
                    <Accent align="left" />
                    <div className={styles.events}>
                        {event1 && <Event event={event1} />}
                        {event2 && <Event event={event2} />}
                        {event3 && <Event event={event3} />}
                    </div>
                </div>
            </section>
        );
    }
}

export default FutureEvents;

const normalizeDate = val => {
    if (isDate(val)) {
        return val;
    } else if (typeof val === "string") {
        return new Date(val);
    } else {
        return val && val.toDate ? val.toDate() : null;
    }
};

const Event = ({ event }) => {
    const {
        eventImage,
        eventCustomImage,
        eventName,
        eventDate,
        eventTime,
        eventLocation,
        eventDescription,
        eventUrl,
        eventCta,
    } = event;
    let theDate = normalizeDate(eventDate);
    let photo = eventCustomImage || eventImage || defaultPhoto;
    let formattedDate = isDate(theDate) ? theDate.toLocaleDateString() : "";
    let shouldShowEvent = showEvent(event);

    if (!shouldShowEvent) {
        return null;
    }

    return (
        <div className={styles.event}>
            <img
                data-blink-src={photo}
                className={styles.img}
                alt={eventName}
            />
            <h2 className={styles.title}>{eventName}</h2>
            {eventDate && (
                <h3 className={styles.info}>
                    <strong>When:</strong> {formattedDate} {eventTime}
                </h3>
            )}
            {eventLocation && (
                <h3 className={styles.info}>
                    <strong>Where:</strong> {eventLocation}
                </h3>
            )}
            <p className={styles.description}>{eventDescription}</p>
            <EventCtaButton eventUrl={eventUrl} eventCta={eventCta} />
        </div>
    );
};

const EventCtaButton = ({ eventUrl, eventCta }) => {
    if (!eventUrl) return null;
    eventCta = eventCta || "Learn More";
    return <LinkButton href={eventUrl} label={eventCta} />;
};
