import React, { Component } from "react";

import Accent from "../Accent";

// still needs styles
import styles from "./styles.module.less";
import defBackgroundImage from "./images/calendly-background.jpg";

class Calendly extends Component {
    constructor(props) {
        super(props);
        this.container = React.createRef();
    }

    componentDidMount() {
        this.appendCalendlyScript();
    }

    componentDidUpdate() {
        this.appendCalendlyScript();
    }

    appendCalendlyScript() {
        if (this.container && this.container.current) {
            const script = document.createElement("script");
            script.setAttribute(
                "src",
                "https://assets.calendly.com/assets/external/widget.js",
            );
            this.container.current.appendChild(script);
        }
    }

    render() {
        const {
            calendar,
            backgroundImage = null,
            darkTitleText = true,
        } = this.props;
        if (!calendar) return null;

        let bgImage = backgroundImage ?? defBackgroundImage;

        let titleStyle = !darkTitleText ? { color: "white" } : {};

        return (
            <section
                ref={this.container}
                name="schedule-appointment"
                className={styles.component}
                data-background="photo"
                data-blink-src={bgImage}>
                <div className={styles.inner}>
                    <div className={styles.container}>
                        <h1 className={styles.title} style={titleStyle}>
                            Schedule a time to chat{" "}
                        </h1>
                        <Accent align="left" />
                        <div className={styles.calendly}>
                            <div className={styles.rectangleTop} />
                            <div
                                className="calendly-inline-widget"
                                data-url={`https://calendly.com/${encodeURIComponent(
                                    calendar,
                                )}`}
                                style={{ height: "400px" }}
                            />
                            <div className={styles.rectangleBottom} />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Calendly;
