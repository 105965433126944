import React, { Component } from "react";
import { debounce, get } from "lodash";

import { Prev, Next } from "../NavArrow";
import Accent from "../Accent";

import banner from "./images/banner.png";
import house from "./images/house.png";
import trophy from "./images/trophy.png";
import styles from "./styles.module.less";

const awardIcons = {
    banner,
    house,
    trophy,
};

export default class Awards extends Component {
    constructor(props) {
        super(props);
        this.awardsList = React.createRef();
    }

    state = {
        leftOffset: 50,
        page: 1,
        totalPages: 1,
        pageOffset: 0,
        perPage: 2,
    };

    componentDidMount() {
        this.setSlideWidth();
        this.paginate();

        if (window) {
            this.respond = debounce(
                e => {
                    this.setSlideWidth();
                    this.paginate();
                },
                75,
                { trailing: true },
            );
            window.addEventListener("resize", this.respond);
            window.addEventListener("orientationchange", this.respond);
        }
    }

    componentWillUnmount() {
        if (!window) return;
        window.removeEventListener("resize", this.respond);
        window.removeEventListener("orientationchange", this.respond);
    }

    setSlideWidth() {
        if (!window) return;
        if (window.matchMedia("(min-width: 768px)").matches) {
            this.setState({ leftOffset: 33.33, perPage: 3 });
        } else {
            this.setState({ leftOffset: 50, perPage: 2 });
        }
    }

    paginate() {
        if (!this.awardsList.current) return;
        const { scrollWidth, clientWidth } = this.awardsList.current;
        if (scrollWidth > clientWidth) {
            let totalPages = Math.ceil(scrollWidth / clientWidth);
            this.setState({ page: 1, pageOffset: clientWidth, totalPages });
        }
    }

    next = e => {
        e.preventDefault();
        let { page } = this.state;
        page++;
        this.setState({ page });
    };

    prev = e => {
        e.preventDefault();
        let { page } = this.state;
        page--;
        if (page < 0) page = 0;
        this.setState({ page });
    };

    renderAwards(awards = []) {
        const { page, pageOffset, perPage, leftOffset } = this.state;
        const l = (awards && awards.length) || 0;
        // "paginate" by sliding left
        const transform = `translateX(-${(page - 1) * pageOffset}px)`;
        // if left than a full page, slide right to center
        let marginLeft = "0";
        if (l < perPage) {
            marginLeft = perPage === 2 ? "25%" : `${leftOffset / l}%`;
        }
        // collect styles
        let slideStyle = {
            transform,
            marginLeft,
        };
        return (
            <ul
                ref={this.awardsList}
                className={styles.awardsList}
                style={slideStyle}>
                {awards.map((award, i) => this.renderAward(award, i))}
            </ul>
        );
    }

    renderAward(award, index = 0) {
        const {
            awardYear,
            awardDescription,
            awardImage = "",
            awardBadge = "",
        } = award;
        const { leftOffset } = this.state;
        let left = `${index * leftOffset}%`;
        let style = {
            left,
        };
        return (
            <li key={index} className={styles.award} style={style}>
                {this.renderCustomImage(awardImage, awardBadge)}
                <h2>
                    <span>{awardYear}</span>
                    {awardDescription}
                </h2>
            </li>
        );
    }

    renderCustomImage(image = "", awardBadge = "") {
        let bg;
        if (image) {
            bg = banner;
        } else if (awardBadge) {
            bg = get(awardIcons, awardBadge, banner);
        } else {
            bg = banner;
        }
        let style = {
            backgroundImage: `url(${bg})`,
        };
        return (
            <div className={styles.awardImage} style={style}>
                {image && <img data-blink-src={image} alt="" />}
            </div>
        );
    }

    render() {
        const { awards } = this.props;
        const { page, totalPages } = this.state;

        if (!awards) return null;

        let prevStyle = {
            display: page === 1 ? "none" : "initial",
        };
        let nextStyle = {
            display: page >= totalPages ? "none" : "initial",
        };

        return (
            <section className={styles.Awards} data-background="dark">
                <div className={styles.container}>
                    <header>
                        <h1 className={styles.title}>Awards</h1>
                        <Accent align="left" />
                    </header>
                    <div className={styles.carousel}>
                        <div className={styles.nav}>
                            <Prev style={prevStyle} onClick={this.prev} />
                        </div>
                        <div className={styles.slides}>
                            {this.renderAwards(awards)}
                        </div>
                        <div className={styles.nav}>
                            <Next style={nextStyle} onClick={this.next} />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
