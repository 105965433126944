import React, { Component, createRef } from "react";
import InnerHTML from "dangerously-set-html-content";

import styles from "./styles.module.less";

class HomebotModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resourcesDivHeight: 0,
        };
        this.resourcesDiv = createRef();
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateHeight);
        this.timeout = setTimeout(() => this.updateHeight(), 1000); // clientHeight is not accurate at mount
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateHeight);
        clearTimeout(this.timeout);
    }

    updateHeight = () => {
        const { resources } = this.props;
        if (!resources || !this.resourcesDiv.current) {
            return;
        }
        this.setState({
            resourcesDivHeight: this.resourcesDiv.current.clientHeight,
        });
    };

    render() {
        const { id = "" } = this.props;
        const content = `<div id="homebot_homeowner"></div> <script> (function (h,b) { var w = window, d = document, s = 'script', x, y; w['__hb_namespace'] = h; w[h] = w[h] || function () { (w[h].q=w[h].q||[]).push(arguments) }; y = d.createElement(s); x = d.getElementsByTagName(s)[0]; y.async = 1; y.src = b; x.parentNode.insertBefore(y,x) })('Homebot','https://embed.homebotapp.com/lgw/v1/widget.js'); Homebot('#homebot_homeowner', '${id}') </script>`;

        return (
            <section className={styles.container} data-background="light">
                <InnerHTML className={styles.content} html={content} />
            </section>
        );
    }
}

export default HomebotModule;
