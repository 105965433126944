import React, { useState, useRef } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "../../../LinkButton/button";
import Collapse from "@material-ui/core/Collapse";

import styles from "./styles.module.less";

const VideoGrid = ({
    videos,
    backgroundImageCustom,
    darkTitleText,
    photoAlignment,
}) => {
    const [URL, setURL] = useState(videos[0].videoUrl);
    const [title, setTitle] = useState(videos[0].videoTitle);
    const [caption, setCaption] = useState(videos[0].videoCaption);
    const [autoplay, setAutoplay] = useState(false);
    const [expandedThumbnails, setExpandedThumbnails] = useState(false);
    const activeVideoRef = useRef(null);

    function handleSetVideo(e, index) {
        e.preventDefault();

        setURL(videos[index].videoUrl);
        setTitle(videos[index].videoTitle);
        setCaption(videos[index].videoCaption);
        activeVideoRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
        setAutoplay(true);
    }

    function handleClickExpandCollapseThumbnails(e) {
        e.preventDefault();

        if (expandedThumbnails) setExpandedThumbnails(false);
        else setExpandedThumbnails(true);
    }

    let style = {
        backgroundPosition: photoAlignment,
    };
    if (!!backgroundImageCustom) {
        style.backgroundImage = `url("${backgroundImageCustom}")`;
    }

    return (
        <section className={styles.Video} style={style} data-background="light">
            <div className={styles.inner}>
                <Grid>
                    <Grid item>
                        <ActiveVideo
                            key="videoActive"
                            URL={URL}
                            title={title}
                            caption={caption}
                            autoplay={autoplay}
                            activeVideoRef={activeVideoRef}
                            darkTitleText={darkTitleText}
                        />
                    </Grid>
                    <Grid item>
                        <Box>
                            <Thumbnails
                                videos={videos}
                                handleClick={handleSetVideo}
                                handleClickExpandCollapseThumbnails={
                                    handleClickExpandCollapseThumbnails
                                }
                                expandedThumbnails={expandedThumbnails}
                                darkTitleText={darkTitleText}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
};
export default VideoGrid;

const ActiveVideo = ({
    URL,
    title,
    caption,
    autoplay = false,
    activeVideoRef,
    darkTitleText,
}) => {
    var src = "";
    var srcBase = "";
    var videoId = "";
    var videoEmbed = null;

    if (URL.includes("youtu")) {
        videoEmbed = (
            <YoutubeEmbed
                url={URL}
                width="600"
                height="338"
                allowPointerEvents={true}
                autoplay={autoplay}
            />
        );
    } else if (URL.includes("vimeo")) {
        srcBase = "https://player.vimeo.com/video/";
        videoId = URL.split(".com/")[1];
        src = srcBase + videoId;

        if (autoplay === 1) {
            src += "?autoplay=1";
        }

        videoEmbed = (
            <VimeoEmbed
                url={URL}
                width="600"
                height="338"
                allowPointerEvents={true}
                autoplay={true}
            />
        );
    } else if (URL.includes("facebook.com")) {
        videoEmbed = (
            <FacebookEmbed
                url={URL}
                width="600"
                height="338"
                allowPointerEvents={true}
                autoplay={true}
            />
        );
    }

    var ampersandPosition = videoId.indexOf("&");
    if (ampersandPosition !== -1) {
        videoId = videoId.substring(0, ampersandPosition);
    }

    let textStyle = !darkTitleText ? { color: "white" } : {};

    return (
        <div className={styles.container} ref={activeVideoRef}>
            <h1 className={styles.title} style={textStyle}>
                {title}
            </h1>
            <div className={styles.videoContainer}>{videoEmbed}</div>
            <div className={styles.body} style={textStyle}>
                {caption}
            </div>
        </div>
    );
};

const Thumbnails = ({
    videos,
    handleClick,
    handleClickExpandCollapseThumbnails,
    expandedThumbnails = false,
    darkTitleText,
}) => {
    var displayShowHideButton = videos.length > 6 ? true : false;

    var videoComponents = videos.map(function(video, i) {
        if (i < 6) {
            var key = `video${i}`;

            return (
                <Thumbnail
                    key={key}
                    index={i}
                    URL={video.videoUrl}
                    title={video.videoTitle}
                    handleClick={handleClick}
                    darkTitleText={darkTitleText}
                />
            );
        }
    });

    var videoComponentsExpanded = videos.map(function(video, i) {
        if (i >= 6) {
            var key = `video${i}`;

            return (
                <Thumbnail
                    key={key}
                    index={i}
                    URL={video.videoUrl}
                    title={video.videoTitle}
                    handleClick={handleClick}
                    darkTitleText={darkTitleText}
                />
            );
        }
    });

    var expandButtonLabel = "Show More";

    if (expandedThumbnails) expandButtonLabel = "Show Less";

    return (
        <div className={styles.thumbnails}>
            <div className={styles.inner}>
                <div className={styles.thumbnailSection}>{videoComponents}</div>
                <Collapse
                    in={expandedThumbnails}
                    style={{
                        overflow: "hidden",
                        width: "100%",
                        height: "0px",
                    }}>
                    <div className={styles.thumbnailSection}>
                        {videoComponentsExpanded}
                    </div>
                </Collapse>
                {displayShowHideButton && (
                    <div
                        style={{
                            width: "100%",
                            textAlign: "center",
                        }}>
                        <div
                            style={{
                                width: "auto",
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}>
                            <Button
                                onClick={handleClickExpandCollapseThumbnails}
                                label={expandButtonLabel}
                                customStyle={{ cursor: "pointer" }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const Thumbnail = ({ index, URL, title, handleClick, darkTitleText }) => {
    var src = "";
    var srcBase = "";
    var videoId = "";

    if (URL.includes("youtu")) {
        if (URL.includes("watch?v=")) {
            videoId = URL.split("v=")[1];
        } else {
            videoId = URL.split("be/")[1];
        }

        srcBase = "https://www.youtube.com/embed/";
        src = "https://img.youtube.com/vi/" + videoId + "/hqdefault.jpg";
    } else if (URL.includes("vimeo")) {
        srcBase = "https://player.vimeo.com/video/";
        videoId = URL.split(".com/")[1];
        src = srcBase + videoId + "&height=140&width=250&";
    } else if (URL.includes("facebook.com")) {
        srcBase =
            "https://www.facebook.com/plugins/video.php?height=140&show_text=false&width=250&href=";
        src = srcBase + encodeURI(URL);
    }

    var ampersandPosition = videoId.indexOf("&");
    if (ampersandPosition !== -1) {
        videoId = videoId.substring(0, ampersandPosition);
    }

    let textStyle = !darkTitleText ? { color: "white" } : {};

    return (
        <div className={styles.container} onClick={e => handleClick(e, index)}>
            {(URL.includes("youtu") && (
                <div className={styles.thumbnailImageContainer}>
                    <div
                        style={{
                            backgroundImage: `url(${src})`,
                            backgroundPosition: "center center",
                            backgroundSize: "cover",
                            width: "250px",
                            height: "140px",
                        }}></div>
                    {/* <img src={src} alt="Video" /> */}
                </div>
            )) ||
                (URL.includes("vimeo") && (
                    <div className={styles.thumbnailEmbedContainer}>
                        <VimeoEmbed url={URL} />
                    </div>
                )) ||
                (URL.includes("facebook.com") && (
                    <div className={styles.thumbnailEmbedContainer}>
                        <FacebookEmbed url={URL} />
                    </div>
                ))}
            <div className={styles.copy}>
                <h1 className={styles.title} style={textStyle}>
                    {title}
                </h1>
            </div>
        </div>
    );
};

const YoutubeEmbed = ({
    url,
    width = "250",
    height = "140",
    autoplay = false,
}) => {
    var videoId = "";

    if (url.includes("watch?v=")) {
        videoId = url.split("v=")[1];
    } else {
        videoId = url.split("be/")[1];
    }

    var srcBase = "https://www.youtube.com/embed/";
    var src = srcBase + videoId;

    if (autoplay) src += "?autoplay=1";

    return (
        <iframe
            src={src}
            frameBorder="0"
            title="Video"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    );
};

const VimeoEmbed = ({
    url,
    width = "250",
    height = "140",
    autoplay = false,
    allowPointerEvents = false,
}) => {
    var srcBase = "https://player.vimeo.com/video/";
    var videoId = url.split(".com/")[1];
    var src = srcBase + videoId + "?height=" + height + "&width=" + width;

    if (autoplay) src += "&autoplay=1";

    var style = {};

    if (!allowPointerEvents) style["pointerEvents"] = "none";

    return (
        <iframe
            src={src}
            title="Video"
            width={width}
            height={height}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={style}
        />
    );
};

const FacebookEmbed = ({
    url,
    width = "250",
    height = "140",
    autoplay = false,
    allowPointerEvents = false,
}) => {
    var srcBase =
        "https://www.facebook.com/plugins/video.php?show_text=false&href=";
    var src = srcBase + encodeURI(url);

    if (autoplay) src += "&autoplay=true";

    var style = {
        border: "none",
        overflow: "hidden",
    };

    if (!allowPointerEvents) style["pointerEvents"] = "none";

    return (
        <iframe
            src={src}
            title="Video"
            style={style}
            scrolling="no"
            frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            allowFullScreen="true"></iframe>
    );
};
