import React, { PureComponent } from "react";
import url from "url";
import FileIcon from "../../../FileIcon";
import styles from "./styles.module.less";

const getFileExtension = file => {
    if (!file) return "";
    let fileUrl = url.parse(file);
    let pathParts = fileUrl.pathname.split("/");
    let filename = pathParts.pop();
    let fileNameParts = filename.split(".");
    let extension = fileNameParts.pop() || "";
    return extension;
};

class Resource extends PureComponent {
    render() {
        let {
            resource: {
                resourceFile = "",
                resourceDescription = "",
                resourceUrl = "",
            } = {},
            isSmallGroup,
        } = this.props;

        // no link or file? do not display
        if (!resourceFile && !resourceUrl) {
            return null;
        }

        let url = resourceUrl || resourceFile;
        let download = !!resourceFile;
        let extension = !!resourceFile ? getFileExtension(resourceFile) : "url";
        return (
            <div
                className={
                    isSmallGroup
                        ? styles.resourceContainerSmallGroup
                        : styles.resourceContainer
                }>
                <a
                    href={url}
                    download={download}
                    target="_blank"
                    rel="noopener noreferrer">
                    <div
                        className={
                            isSmallGroup
                                ? styles.contentWrapperSmallGroup
                                : styles.contentWrapper
                        }>
                        <div
                            className={
                                isSmallGroup
                                    ? styles.iconContainerSmallGroup
                                    : styles.iconContainer
                            }>
                            <FileIcon
                                extension={extension}
                                alt={resourceDescription}
                                className={styles.resourceImage}
                            />
                        </div>
                        <div
                            className={
                                isSmallGroup
                                    ? styles.resourceDescriptionSmallGroup
                                    : styles.resourceDescription
                            }>
                            <p>{resourceDescription || "Download"}</p>
                        </div>
                    </div>
                </a>
            </div>
        );
    }
}

export default Resource;
