import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Popup from "./Popup";
import Marker from "./Marker";

class Map extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            PopupClicked: true,
        };
    }

    disable = e => {
        e.stopPropagation();
        e.preventDefault();
    };
    handleInteraction = e => {
        return this.props.isPreview ? this.disable(e) : true;
    };

    handleClick = () => {
        if (this.state.PopupClicked === false) {
            this.setState({ PopupClicked: true });
        } else {
            this.setState({ PopupClicked: false });
        }
    };
    render() {
        const { branch, isPreview } = this.props;
        const ApiKey = process.env.GATSBY_GOOGLE_MAP_API_KEY;

        if (!branch) return null;

        return (
            // Important! Always set the container height explicitly
            <section
                onClick={this.handleInteraction}
                onScroll={this.handleInteraction}
                style={{ height: "345px", width: "100%", marginTop: "60px" }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: ApiKey }}
                    defaultCenter={[branch.lat, branch.lon]}
                    center={[branch.lat, branch.lon]}
                    defaultZoom={11}
                    options={
                        isPreview
                            ? {
                                  panControl: false,
                                  gestureHandling: "none",
                                  draggable: false,
                                  zoomControl: false,
                                  scrollwheel: false,
                                  disableDoubleClickZoom: false,
                                  fullScreenOption: false,
                              }
                            : {}
                    }>
                    {this.state.PopupClicked ? (
                        <Popup
                            lat={branch.lat}
                            lng={branch.lon}
                            title={branch.title}
                            address={[
                                branch.address1,
                                branch.address2,
                                branch.address3,
                                branch.city,
                                branch.state,
                                branch.zip,
                            ]}
                            handleClick={this.handleClick}
                        />
                    ) : (
                        <Marker
                            lat={branch.lat}
                            lng={branch.lon}
                            title={branch.title}
                            handleClick={this.handleClick}
                        />
                    )}
                </GoogleMapReact>
            </section>
        );
    }
}

Map.defaultProps = {
    branch: {
        title: "Bay Equity Home Loans",
        address1: "28 Liberty Ship Way",
        address2: "Ste 2800",
        address3: "",
        city: "Sausalito",
        state: "CA",
        zip: "94965",
        lat: 37.8623892,
        lon: -122.4942879,
    },
};

export default Map;
