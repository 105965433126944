import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";

import { set, cloneDeep } from "lodash";
import SimpleReactValidator from "simple-react-validator";

import { HTMLContent } from "../Content";
import Accent from "../Accent";
import CustomForm from "../CustomForm";

import styles from "./styles.module.less";
import heroes from "./components/Hero/heroes.module.less";
import predefinedInputs from "./lib/predefinedInputs";

const defaultPhoto = `anglelineBackground`;

const ContactUsModule = ({
    firestoreId,
    pageTitle,
    moduleIndex,
    branch,
    isPreview,
    formTitle,
    fields,
    img,
    title,
    html,
    darkText,
    customPhoto,
    photoAlignment,
    pageUrl,
    bammUrl,
    profile,
    confirmEmailText,
    confirmEmailFrom,
    leadEmailCcList,
    templateKey,
    gallery,
    contact,
    ownerSlug,
    staticTextItems = [],
}) => {
    const [formValues, setFormValues] = useState({
        botField: "",
        disclosure: false,
    });
    const [defaultFormValues, setDefaultFormValues] = useState([]);
    const [showSuccessMessage, setShowSuccessMessage] = useState(0);
    const [netlifyError, setNetlifyError] = useState("");

    const contactUsComment = darkText
        ? `${styles.contactUsComment} ${styles.contactUsDark}`
        : styles.contactUsComment;
    let heroBackground = !!img ? img : defaultPhoto;
    let cls = classnames(styles.ContactUs, {
        [heroes[heroBackground]]: !customPhoto,
    });
    let style = {
        backgroundPosition: photoAlignment || "center",
    };
    if (!!customPhoto) {
        style.backgroundImage = `url("${customPhoto}")`;
    }

    useEffect(() => {
        const formValuesTmp = cloneDeep(formValues);
        const errors = {};
        let customCounter = 0;

        fields &&
            fields.forEach(field => {
                if (!field.type) return;

                if (field.type !== "custom") {
                    let inputs = predefinedInputs.fields[field.type].inputs;
                    inputs.forEach(input => {
                        set(formValuesTmp, input.name, "");
                        set(errors, input.name, "");
                    });
                } else {
                    // set name for custom field
                    customCounter += 1;
                    const name = "custom" + customCounter;
                    field.name = name;

                    // set default value
                    let defaultValue = "";

                    if (field.customFieldType === "checkboxList") {
                        defaultValue = {};

                        field.options.forEach(option => {
                            set(defaultValue, option.value, false);
                        });
                    }

                    set(formValuesTmp, name, defaultValue);
                    set(errors, name, "");
                }

                // set disclosure to be unchecked by default
                set(formValuesTmp, "disclosure", "");
            });

        setFormValues(formValuesTmp);
        setDefaultFormValues(formValuesTmp);
    }, []);

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                customPhone: {
                    message: "The :attribute must be a valid phone number.",
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(
                            val,
                            /^(\+?0?1)?[(.-]?\d{3}[).-]?[\s.-]?\d{3}[\s.-]?\d{4}$/g,
                        );
                    },
                },
                customEmail: {
                    message: "The :attribute must be a valid email address.",
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(
                            val, // from https://emailregex.com/
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                        );
                    },
                },
            },
            messages: {
                required: "This field is required",
            },
        }),
    );

    const handleBlur = e => {
        validator.current.showMessageFor(e.target.name);
    };

    const handleChange = e => {
        const { name } = e.target;
        const value =
            e.target.type === "checkbox" ? e.target.checked : e.target.value;

        let formValuesCopy = cloneDeep(formValues);
        set(formValuesCopy, name, value);
        setFormValues(formValuesCopy);

        if (!isPreview) validator.current.showMessageFor(e.target.name);
    };

    const handleFormSubmitClick = () => {
        if (!isPreview) {
            if (validator.current.allValid()) {
                validator.current.visibleFields = [];
                integrateNetlify();
            } else {
                validator.current.showMessages();
            }
        }
    };

    const integrateNetlify = () => {
        const body = new URLSearchParams();
        const formData = [];

        body.append("form-name", process.env.GATSBY_CONTACT_FORM_SPLASH);
        body.append("botField", formValues.botField);
        body.append(
            "electronicRecordsConsent",
            formValues.electronicRecordsConsent ? "Yes" : "No",
        );
        body.append("formConsent", formValues.disclosure ? "Yes" : "No");

        fields.forEach(field => {
            if (!!field.type) {
                if (field.type !== "custom") {
                    const fieldInfo = predefinedInputs["fields"][field.type];

                    fieldInfo.inputs.forEach(input => {
                        let fieldData = {};

                        // predefined field - append the value
                        body.append(input.name, formValues[input.name]);
                        let bammValue = "";
                        let leadValue = "";
                        let displayValue = "";

                        // no predefined fields are radios, so we only need a special case for select and checkbox inputs
                        if (input.inputType === "select") {
                            input.options.forEach(option => {
                                if (formValues[input.name] === option.value) {
                                    displayValue = option.label;

                                    if (!!input.hasBammField)
                                        bammValue =
                                            input.name == "state"
                                                ? option.label
                                                : option.value;

                                    if (!!input.hasLeadField)
                                        leadValue = [
                                            "state",
                                            "propertyState",
                                        ].includes(input.name)
                                            ? option.label
                                            : option.value;
                                }
                            });
                        } else if (input.inputType == "checkbox") {
                            // checkbox - show "Yes" or "No"
                            if (!!formValues[input.name]) displayValue = "Yes";
                            else displayValue = "No";
                            if (!!input.hasLeadField) leadValue = displayValue;
                        } else {
                            displayValue = formValues[input.name];

                            if (!!input.hasBammField) bammValue = displayValue;
                            if (!!input.hasLeadField) leadValue = displayValue;
                        }

                        fieldData["name"] = input.name;
                        fieldData["label"] = input.label;
                        fieldData["value"] = displayValue;
                        fieldData["bammName"] = input.bammName;
                        fieldData["bammValue"] = bammValue;
                        fieldData["leadName"] = input.leadName;
                        fieldData["leadValue"] = leadValue;

                        console.log("fieldData", fieldData);

                        // if both electronicRecordsConsent and disclosure are unchecked, suppress the phone number
                        // if electronicRecordsConsent is checked and disclosure is not, suppress the phone number
                        // if both electronicRecordsConsent and disclosure are checked, send the phone number
                        if (
                            (input.bammName === "Cell_Phone" ||
                                ["CellPhone", "DayPhone", "HomePhone"].includes(
                                    input.leadName,
                                )) &&
                            !(
                                formValues.electronicRecordsConsent &&
                                formValues.disclosure
                            )
                        ) {
                            fieldData["bammName"] = "";
                            fieldData["bammValue"] = "";
                            fieldData["leadName"] = "";
                            fieldData["leadValue"] = "";
                        }

                        formData.push(fieldData);
                    });
                } else {
                    // custom field - show the value as "'Field Label' = field value"
                    let customLabel = "'" + field.label + "' = ";
                    let bammValue = "";
                    let displayValue = "";
                    let fieldData = {};

                    if (field.customFieldType === "checkboxList") {
                        // checkbox list - show a comma-separated list of the checked items' labels, or "None Selected" if none are checked
                        let selectedLabels = [];

                        field.options.forEach(option => {
                            if (formValues[field.name][option.value] === true) {
                                selectedLabels.push(option.label);
                            }
                        });

                        if (selectedLabels.length === 0) {
                            displayValue = "None selected";
                        } else {
                            displayValue = selectedLabels.join(", ");
                        }

                        body.append(field.name, customLabel + displayValue);

                        // there are no checkbox bamm fields, so just set bammValue to blank
                        bammValue = "";
                    } else if (field.customFieldType === "checkbox") {
                        // checkbox - show "Yes" or "No"
                        if (!!formValues[field.name]) displayValue = "Yes";
                        else displayValue = "No";

                        body.append(field.name, customLabel + displayValue);

                        // there are no checkbox bamm fields, so just set bammValue to blank
                        bammValue = "";
                    } else if (
                        field.customFieldType === "radio" ||
                        field.customFieldType === "select"
                    ) {
                        // checkbox list - show the checked item's label, or "None Selected" if none are checked
                        displayValue = "None Selected";

                        field.options.forEach(option => {
                            if (formValues[field.name] === option.value) {
                                displayValue = option.label;
                                bammValue = option.value;
                            }
                        });

                        body.append(field.name, customLabel + displayValue);
                    } else {
                        // text and textarea - show the entered value
                        body.append(
                            field.name,
                            customLabel + formValues[field.name],
                        );
                        displayValue = formValues[field.name];
                        bammValue = formValues[field.name];
                    }

                    // set fieldData
                    fieldData["name"] = field.name;
                    fieldData["label"] = field.label;
                    fieldData["value"] = displayValue;

                    if (field.bammName) {
                        fieldData["bammName"] = field.bammName;
                        fieldData["bammValue"] = bammValue;
                    } else {
                        fieldData["bammName"] = null;
                        fieldData["bammValue"] = "";
                    }

                    fieldData["leadName"] = null;
                    fieldData["leadValue"] = "";

                    formData.push(fieldData);
                }
            }
        });

        // add electronic records consent to formData
        formData.push({
            name: "electronicRecordsConsent",
            label: "Electronic Records Consent",
            value: formValues.electronicRecordsConsent ? "Yes" : "No",
            bammName: "",
            bammValue: "",
            leadName: "ElectronicRecordsConsent",
            leadValue: formValues.electronicRecordsConsent ? "Yes" : "No",
        });

        // add form consent to formData
        formData.push({
            name: "formConsent",
            label: "Form Consent",
            value: formValues.disclosure ? "Yes" : "No",
            bammName: "",
            bammValue: "",
            leadName: "FormConsent",
            leadValue: formValues.disclosure ? "Yes" : "No",
        });

        body.append("formData", JSON.stringify(formData));

        // const { ownerSlug = "" } = this.props;
        const ownerUrl = !!ownerSlug
            ? `https://bayequityhomeloans.com${ownerSlug}`
            : "";

        let pageData = {
            firestoreId: firestoreId,
            pageTitle: pageTitle,
            pageUrl: pageUrl || "",
            bammUrl: bammUrl || "",
            ownerPhoto: "",
            ownerName: "",
            ownerLicenses: JSON.stringify(profile.licenseIds || []),
            confirmEmailText: confirmEmailText || "",
            confirmEmailFrom: confirmEmailFrom || "",
            ownerUrl: ownerUrl || "",
            ownerCC: leadEmailCcList || "",
        };

        if (templateKey === "branch") {
            pageData = {
                ...pageData,
                ownerLogo: branch.dbaLogoBlack || "",
                ownerTitle: branch.title || "",
                ownerEmail: branch.email || "",
                ownerPhone: branch.phone || "",
                ownerMobile: branch.mobilePhone || "",
                ownerFax: branch.fax || "",
                address1: branch.address1 || "",
                address2: branch.address2 || "",
                address3: branch.address3 || "",
                city: branch.city || "",
                state: branch.state || "",
                zip: branch.zip || "",
            };
        } else {
            pageData = {
                ...pageData,
                ownerLogo: profile.branch.dbaLogoBlack || "",
                ownerPhoto: gallery.photo || "",
                ownerName: profile.name || "",
                ownerTitle: profile.jobTitle || "",
                ownerEmail: profile.email || "",
                ownerPhone: contact.phone || "",
                ownerMobile: contact.mobilePhone || "",
                ownerFax: contact.fax || "",
                address1: profile.branch.address1 || "",
                address2: profile.branch.address2 || "",
                address3: profile.branch.address3 || "",
                city: profile.branch.city || "",
                state: profile.branch.state || "",
                zip: profile.branch.zip || "",
            };
        }

        body.append("pageData", JSON.stringify(pageData));

        fetch(ownerSlug, {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body,
        })
            .then(() => {
                setFormValues(defaultFormValues);
                setShowSuccessMessage(true);
            })
            .catch(err => {
                setNetlifyError(err.message);
            });
    };

    return (
        <section className={cls} style={style}>
            <div className={styles.inner}>
                <div
                    className={`${styles.contactUs} ${styles.contactUsMobile}`}>
                    <div className={styles.contactUsBody}>
                        <div style={{ margin: "2%", position: "relative" }}>
                            <div className={styles.contactUsInner}>
                                <div className={styles.row}>
                                    <div className={styles.col}>
                                        <div className={styles.card}>
                                            <div className={styles.cardBody}>
                                                <div className={styles.row}>
                                                    <div
                                                        className={
                                                            styles.col12
                                                        }>
                                                        <CustomForm
                                                            moduleIndex={
                                                                moduleIndex
                                                            }
                                                            formTitle={
                                                                formTitle
                                                            }
                                                            formValues={
                                                                formValues
                                                            }
                                                            fields={fields}
                                                            isPreview={
                                                                isPreview
                                                            }
                                                            predefinedInputs={
                                                                predefinedInputs
                                                            }
                                                            validator={
                                                                validator
                                                            }
                                                            handleBlur={
                                                                handleBlur
                                                            }
                                                            handleChange={
                                                                handleChange
                                                            }
                                                            handleFormSubmit={
                                                                handleFormSubmitClick
                                                            }
                                                            staticTextItems={
                                                                staticTextItems
                                                            }
                                                        />
                                                        {!!showSuccessMessage && (
                                                            <div
                                                                className={
                                                                    styles.successMessage
                                                                }>
                                                                Thank you for
                                                                your interest -
                                                                we will contact
                                                                you soon!
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span className={styles.customArrow} />
                        </div>
                    </div>
                    <span className={styles.contactUsSeparator} />
                    <div
                        className={contactUsComment}
                        style={{ marginBottom: "auto" }}>
                        <h1 className={styles.title}>{title}</h1>
                        <Accent align="left" />
                        <HTMLContent content={html} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactUsModule;
