import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import * as d3 from "d3";
import Accent from "../../../Accent";
import styles from "./styles.module.less";

const CalculatorSavings = ({
    purchasePrice,
    downPayment,
    loanAmount,
    handlePurchasePriceFieldValueChanged,
    handleDownPaymentFieldValueChanged,
    lenderCreditPercentage,
    lenderCreditAmount,
    realtorCommissionPercentage,
    realtorCommissionAmount,
    realtorCreditPercentage,
    realtorCreditAmount,
    totalCredit,
}) => {
    const validator = useRef(new SimpleReactValidator({}));

    const isWholeNumber = number => {
        return (number - Math.floor(number)).toFixed(2) === "0.00";
    };

    const getFormattedCurrency = number => {
        if (isWholeNumber(number)) {
            // format with no decimals
            return d3.format("$,.0f")(number);
        } else {
            // format with 2 decimals
            return d3.format("$,.2f")(number);
        }
    };

    return (
        <div className={styles.savingsPanel}>
            <h3 className={styles.savingsTitle}>
                Giving to Heroes {d3.format("($,.2f")(purchasePrice)} Purchase
            </h3>
            <Accent align="left" />
            <div className={styles.savingsInputLine}>
                <div className={styles.col12}>
                    <div className={styles.formGroup}>
                        <label htmlFor="purchasePrice">
                            Change Purchase Price:
                        </label>{" "}
                        <input
                            type="text"
                            name="purchasePrice"
                            value={purchasePrice}
                            onBlur={() =>
                                validator.current.showMessageFor(
                                    "changePurchasePrice",
                                )
                            }
                            onChange={handlePurchasePriceFieldValueChanged}
                        />
                        <div className={styles.validationMessage}>
                            {validator.current.message(
                                "changePurchasePrice",
                                purchasePrice,
                                "required|currency",
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.savingsInputLine}>
                <div className={styles.col12}>
                    <div className={styles.formGroup}>
                        <label htmlFor="downpayment">
                            Change Down Payment:
                        </label>{" "}
                        <input
                            type="text"
                            name="downPayment"
                            value={downPayment}
                            onBlur={() =>
                                validator.current.showMessageFor(
                                    "changeDownPayment",
                                )
                            }
                            onChange={handleDownPaymentFieldValueChanged}
                        />
                        <div className={styles.validationMessage}>
                            {validator.current.message(
                                "changeDownPayment",
                                downPayment,
                                "required|currency",
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.calculatedValues}>
                <h4>
                    Lender Credit ({d3.format(".2%")(lenderCreditPercentage)} of
                    the Loan Amount:)
                </h4>
                <p>
                    {getFormattedCurrency(loanAmount)} x{" "}
                    {d3.format(".2%")(lenderCreditPercentage)} ={" "}
                    {getFormattedCurrency(lenderCreditAmount)} credit back
                </p>
                <h4>
                    Realtor Credit ({d3.format(".0%")(realtorCreditPercentage)}{" "}
                    of Realtor commission:)
                </h4>
                <p>
                    {getFormattedCurrency(purchasePrice)} x{" "}
                    {d3.format(".2%")(realtorCommissionPercentage)} ={" "}
                    {getFormattedCurrency(realtorCommissionAmount)}
                </p>
                <p>
                    {getFormattedCurrency(realtorCommissionAmount)} x{" "}
                    {d3.format(".0%")(realtorCreditPercentage)} ={" "}
                    {getFormattedCurrency(realtorCreditAmount)} credit back
                </p>
            </div>
            <p className={`${styles.calcTotal} ${styles.totalSavings}`}>
                Total Savings (Lender Credit + Realtor Credit):{" "}
                {getFormattedCurrency(totalCredit)}
                <sup>*</sup>
            </p>
            <div className={styles.smallprint}>
                <sup>*</sup>Savings example above is for educational purposes
                only and subject to change based on transaction specifics,
                including but not limited to: purchase price, loan amount,
                Realtor earned commission, etc. To receive the Realtor Credit
                for Purchase or Sale transactions your Realtor must be a "Giving
                To Heroes" affiliate. Lender credit does not apply to Sale
                transactions and Realtor credit does not apply to refinance
                transactions.
            </div>
        </div>
    );
};

export default CalculatorSavings;
