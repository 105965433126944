import React from "react";

import {
    returnStateAbbrev,
    formatPhoneAreaCode,
    formatPhoneNumber,
} from "../../../../lib/helpers";

import styles from "./styles.module.less";

const ContactInfo = ({
    phone,
    mobilePhone,
    email,
    fax,
    address1,
    address2,
    address3,
    city,
    state,
    zip,
    overrideAddress,
    overrideAddressText,
}) => {
    return (
        <div>
            {phone && (
                <div>
                    <div className={styles.first} aria-label="Telephone number">
                        P:
                    </div>
                    <div>
                        <a href={`tel:${formatPhoneNumber(phone)}`}>
                            {formatPhoneAreaCode(phone)}
                        </a>
                    </div>
                </div>
            )}
            {mobilePhone && (
                <div>
                    <div
                        className={styles.first}
                        aria-label="Mobile telephone number">
                        M:
                    </div>
                    <div>
                        <a href={`tel:${formatPhoneNumber(mobilePhone)}`}>
                            {formatPhoneAreaCode(mobilePhone)}
                        </a>
                    </div>
                </div>
            )}
            {email && (
                <div>
                    <div className={styles.first} aria-label="Email address">
                        E:
                    </div>
                    <div>
                        <a href={`mailto:${email}`}>{email}</a>
                    </div>
                </div>
            )}
            {fax && (
                <div>
                    <div className={styles.first} aria-label="Fax number">
                        F:
                    </div>
                    <div>{formatPhoneAreaCode(fax)}</div>
                </div>
            )}
            {!overrideAddress && (
                <>
                    {address1 && (
                        <div>
                            <div className={styles.first} aria-label="Address">
                                A:
                            </div>
                            <div>{address1}</div>
                        </div>
                    )}
                    {address2 && (
                        <div>
                            <div className={styles.first} />
                            <div>{address2}</div>
                        </div>
                    )}
                    {address3 && (
                        <div>
                            <div className={styles.first} />
                            <div>{address3}</div>
                        </div>
                    )}
                    <div>
                        <div className={styles.first} />
                        <div>
                            {city}
                            {state ? ", " + returnStateAbbrev(state) : ""} {zip}
                        </div>
                    </div>
                </>
            )}
            {overrideAddress && (
                <div style={{ whiteSpace: "break-spaces" }}>
                    {overrideAddressText}
                </div>
            )}
        </div>
    );
};

export default ContactInfo;
