import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { set, cloneDeep } from "lodash";
import SimpleReactValidator from "simple-react-validator";

import { HTMLContent } from "../Content";
import Accent from "../Accent";
import CustomForm from "../CustomForm";
import Savings from "./components/Savings";

import styles from "./styles.module.less";
import heroes from "./components/Hero/heroes.module.less";
import predefinedInputs from "./lib/predefinedInputs";

const defaultPhoto = `anglelineBackground`;

const G2HCalculatorModule = ({
    firestoreId,
    pageTitle,
    moduleIndex,
    branch,
    isPreview,
    formTitle,
    fields,
    img,
    title,
    html,
    darkText,
    customPhoto,
    lenderCreditPercentage,
    realtorCommissionPercentage,
    realtorCreditPercentage,
    leadEmailInfo,
    bammUrl,
    branches,
}) => {
    const [formValues, setFormValues] = useState({
        botField: "",
        disclosure: false,
    });
    const [lenderCreditAmount, setLenderCreditAmount] = useState(0);
    const [realtorCommissionAmount, setRealtorCommissionAmount] = useState(0);
    const [realtorCreditAmount, setRealtorCreditAmount] = useState(0);
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [downPayment, setDownPayment] = useState(0);
    const [loanAmount, setLoanAmount] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [showSavings, setShowSavings] = useState(0);
    const [netlifyError, setNetlifyError] = useState("");
    const [selectedBranch, setSelectedBranch] = useState(leadEmailInfo);
    const [showBranchesInput, setShowBranchesInput] = useState(!!!branch);
    const [
        branchLenderCreditPercentage,
        setBranchLenderCreditPercentage,
    ] = useState(lenderCreditPercentage);
    const [
        branchRealtorCommissionPercentage,
        setBranchRealtorCommissionPercentage,
    ] = useState(realtorCommissionPercentage);
    const [
        branchRealtorCreditPercentage,
        setBranchRealtorCreditPercentage,
    ] = useState(realtorCreditPercentage);

    const contactUsComment = darkText
        ? `${styles.contactUsComment} ${styles.contactUsDark}`
        : styles.contactUsComment;
    let heroBackground = !!img ? img : defaultPhoto;
    let cls = classnames(styles.ContactUs, {
        [heroes[heroBackground]]: !customPhoto,
    });
    let style = {};
    if (!!customPhoto) {
        style.backgroundImage = `url("${customPhoto}")`;
    }

    const handleFormSubmit = e => {
        e.preventDefault();
        if (!isPreview) {
            if (validator.current.allValid()) {
                calculateSavings(true);
                setShowSavings(true);
            }
        } else {
            validator.current.showMessage();
        }
    };

    const handleBlur = e => {
        validator.current.showMessageFor(e.target.name);
    };

    const handleChange = e => {
        const { name } = e.target;
        const value =
            e.target.type === "checkbox" ? e.target.checked : e.target.value;

        let formValuesCopy = cloneDeep(formValues);
        set(formValuesCopy, name, value);
        setFormValues(formValuesCopy);

        if (name === "purchasePrice") setPurchasePrice(value);
        if (name === "downPayment") setDownPayment(value);

        if (!isPreview) validator.current.showMessageFor(e.target.name);
    };

    const handleChangePurchasePrice = e => {
        setPurchasePrice(e.target.value);
    };

    const handleChangeDownPayment = e => {
        setDownPayment(e.target.value);
    };

    const integrateNetlify = () => {
        const body = new URLSearchParams();
        const formData = [];

        body.append("form-name", process.env.GATSBY_CALCULATOR_FORM_G2H);
        body.append("branchid", selectedBranch.slug);
        body.append("branchName", selectedBranch.name);
        body.append("branchEmail", selectedBranch.email);
        body.append("loName", selectedBranch.loanOfficerName);
        body.append("loEmail", selectedBranch.email);
        body.append("realtorName", selectedBranch.realtorName ?? "");
        body.append("realtorTitle", selectedBranch.realtorTitle ?? "");
        body.append(
            "electronicRecordsConsent",
            formValues.electronicRecordsConsent ? "Yes" : "No",
        );
        body.append("formConsent", formValues.disclosure ? "Yes" : "No");

        fields.forEach(field => {
            if (!!field.type) {
                if (field.type !== "custom") {
                    const fieldInfo = predefinedInputs["fields"][field.type];

                    fieldInfo.inputs.forEach(input => {
                        let fieldData = {};

                        // predefined field - append the value
                        body.append(input.name, formValues[input.name]);
                        let bammValue = "";
                        let displayValue = "";

                        // no predefined fields are checkboxes or radios, so we only need a special case for select inputs
                        if (input.inputType === "select") {
                            input.options.forEach(option => {
                                if (formValues[input.name] === option.value) {
                                    displayValue = option.label;

                                    if (!!input.hasBammField)
                                        bammValue = option.value;
                                }
                            });
                        } else {
                            displayValue = formValues[input.name];

                            if (!!input.hasBammField) bammValue = displayValue;
                        }

                        fieldData["name"] = input.name;
                        fieldData["label"] = input.label;
                        fieldData["value"] = displayValue;
                        fieldData["bammName"] = input.bammName;
                        fieldData["bammValue"] = bammValue;

                        // if both electronicRecordsConsent and disclosure are unchecked, suppress the phone number
                        // if electronicRecordsConsent is checked and disclosure is not, suppress the phone number
                        // if both electronicRecordsConsent and disclosure are checked, send the phone number
                        if (
                            input.bammName === "Cell_Phone" &&
                            !(
                                formValues.electronicRecordsConsent &&
                                formValues.disclosure
                            )
                        ) {
                            fieldData["bammName"] = "";
                            fieldData["bammValue"] = "";
                        }

                        formData.push(fieldData);
                    });
                } else {
                    // custom field - show the value as "'Field Label' = field value"
                    let customLabel = "'" + field.label + "' = ";
                    let bammValue = "";
                    let displayValue = "";
                    let fieldData = {};

                    if (field.customFieldType === "checkboxList") {
                        // checkbox list - show a comma-separated list of the checked items' labels, or "None Selected" if none are checked
                        let selectedLabels = [];

                        field.options.forEach(option => {
                            if (formValues[field.name][option.value] === true) {
                                selectedLabels.push(option.label);
                            }
                        });

                        if (selectedLabels.length === 0) {
                            displayValue = "None selected";
                        } else {
                            displayValue = selectedLabels.join(", ");
                        }

                        body.append(field.name, customLabel + displayValue);

                        // there are no checkbox bamm fields, so just set bammValue to blank
                        bammValue = "";
                    } else if (field.customFieldType === "checkbox") {
                        // checkbox - show "Yes" or "No"
                        if (!!formValues[field.name]) displayValue = "Yes";
                        else displayValue = "No";

                        body.append(field.name, customLabel + displayValue);

                        // there are no checkbox bamm fields, so just set bammValue to blank
                        bammValue = "";
                    } else if (
                        field.customFieldType === "radio" ||
                        field.customFieldType === "select"
                    ) {
                        // checkbox list - show the checked item's label, or "None Selected" if none are checked
                        displayValue = "None Selected";

                        field.options.forEach(option => {
                            if (formValues[field.name] === option.value) {
                                displayValue = option.label;
                                bammValue = option.value;
                            }
                        });

                        body.append(field.name, customLabel + displayValue);
                    } else {
                        // text and textarea - show the entered value
                        body.append(
                            field.name,
                            customLabel + formValues[field.name],
                        );
                        displayValue = formValues[field.name];
                        bammValue = formValues[field.name];
                    }

                    // set fieldData
                    fieldData["name"] = field.name;
                    fieldData["label"] = field.label;
                    fieldData["value"] = displayValue;

                    if (field.bammName) {
                        fieldData["bammName"] = field.bammName;
                        fieldData["bammValue"] = bammValue;
                    } else {
                        fieldData["bammName"] = null;
                        fieldData["bammValue"] = "";
                    }

                    formData.push(fieldData);
                }
            }
        });

        body.append("formData", JSON.stringify(formData));

        let pageData = {
            firestoreId: firestoreId,
            pageTitle: pageTitle,
            bammUrl: bammUrl || "",
        };

        body.append("pageData", JSON.stringify(pageData));

        fetch(selectedBranch.slug, {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body,
        })
            .then(() => {
                setShowSavings(true);
            })
            .catch(err => {
                setShowSavings(false);
                setNetlifyError(err.message);
            });
    };

    const calculateSavings = (doNetlifyIntegration = false) => {
        if (
            validator.current.check(purchasePrice, "required|currency") &&
            validator.current.check(downPayment, "required|currency")
        ) {
            let loanAmount = purchasePrice - downPayment;
            let lenderCreditAmount = loanAmount * branchLenderCreditPercentage;
            let realtorCommissionAmount =
                purchasePrice * branchRealtorCommissionPercentage;
            let realtorCreditAmount =
                realtorCommissionAmount * branchRealtorCreditPercentage;
            let totalCredit = lenderCreditAmount + realtorCreditAmount;

            setLoanAmount(loanAmount);
            setLenderCreditAmount(lenderCreditAmount);
            setRealtorCommissionAmount(realtorCommissionAmount);
            setRealtorCreditAmount(realtorCreditAmount);
            setTotalCredit(totalCredit);

            if (doNetlifyIntegration) integrateNetlify();
        }
    };

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                customPhone: {
                    message: "The :attribute must be a valid phone number.",
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(
                            val,
                            /^(\+?0?1)?[(.-]?\d{3}[).-]?[\s.-]?\d{3}[\s.-]?\d{4}$/g,
                        );
                    },
                },
                purchasePrice: {
                    message: "The :attribute must be a valid amount.",
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(
                            val,
                            /^[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/g,
                        );
                    },
                },
                customEmail: {
                    message: "The :attribute must be a valid email address.",
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(
                            val, // from https://emailregex.com/
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                        );
                    },
                },
            },
            messages: {
                required: "This field is required",
            },
        }),
    );

    useEffect(() => {
        const formValuesTmp = { botField: "", disclosure: false };
        const errors = {};
        let customCounter = 0;

        fields &&
            fields.forEach(field => {
                if (!field.type) return;

                if (field.type !== "custom") {
                    let inputs = predefinedInputs.fields[field.type].inputs;
                    inputs.forEach(input => {
                        set(formValuesTmp, input.name, "");
                        set(errors, input.name, "");
                    });
                } else {
                    // set name for custom field
                    customCounter += 1;
                    const name = "custom" + customCounter;
                    field.name = name;

                    // set default value
                    let defaultValue = "";

                    if (field.customFieldType === "checkboxList") {
                        defaultValue = {};

                        field.options.forEach(option => {
                            set(defaultValue, option.value, false);
                        });
                    }

                    set(formValuesTmp, name, defaultValue);
                    set(errors, name, "");
                }

                // set disclosure to be unchecked by default
                set(formValuesTmp, "disclosure", "");
            });

        if (showBranchesInput) {
            set(formValuesTmp, "branchId", "");
        }

        setFormValues(formValuesTmp);
    }, []);

    useEffect(() => {
        calculateSavings(false);
    }, [purchasePrice, downPayment]);

    useEffect(() => {
        if (formValues.branchId !== undefined) {
            let branchIndex = branches.findIndex(
                x => x.slug === formValues.branchId,
            );
            setSelectedBranch(branches[branchIndex]);
            if (branches[branchIndex]) {
                setBranchLenderCreditPercentage(
                    branches[branchIndex].lenderCreditPercentage,
                );
                setBranchRealtorCommissionPercentage(
                    branches[branchIndex].realtorCommissionPercentage,
                );
                setBranchRealtorCreditPercentage(
                    branches[branchIndex].realtorCreditPercentage,
                );
            } else {
                setBranchLenderCreditPercentage(lenderCreditPercentage);
                setBranchRealtorCommissionPercentage(
                    realtorCommissionPercentage,
                );
                setBranchRealtorCreditPercentage(realtorCreditPercentage);
            }
        }
    }, [formValues.branchId]);

    return (
        <section className={cls} style={style}>
            <div className={styles.inner}>
                <div
                    className={`${styles.contactUs} ${styles.contactUsMobile}`}>
                    <div className={styles.contactUsBody}>
                        <div style={{ margin: "2%", position: "relative" }}>
                            <div className={styles.contactUsInner}>
                                <div className={styles.row}>
                                    <div className={styles.col}>
                                        <div className={styles.card}>
                                            <div className={styles.cardBody}>
                                                <div className={styles.row}>
                                                    <div
                                                        className={
                                                            styles.col12
                                                        }>
                                                        {!showSavings && (
                                                            <CustomForm
                                                                moduleIndex={
                                                                    moduleIndex
                                                                }
                                                                formTitle={
                                                                    formTitle
                                                                }
                                                                fields={fields}
                                                                isPreview={
                                                                    isPreview
                                                                }
                                                                formValues={
                                                                    formValues
                                                                }
                                                                predefinedInputs={
                                                                    predefinedInputs
                                                                }
                                                                validator={
                                                                    validator
                                                                }
                                                                handleBlur={
                                                                    handleBlur
                                                                }
                                                                handleChange={
                                                                    handleChange
                                                                }
                                                                handleFormSubmit={
                                                                    handleFormSubmit
                                                                }
                                                                showBranchesInput={
                                                                    showBranchesInput
                                                                }
                                                                branches={
                                                                    branches
                                                                }
                                                                submitButtonText="See What You May Save!"
                                                            />
                                                        )}
                                                        {!!showSavings && (
                                                            <Savings
                                                                purchasePrice={
                                                                    purchasePrice
                                                                }
                                                                downPayment={
                                                                    downPayment
                                                                }
                                                                loanAmount={
                                                                    loanAmount
                                                                }
                                                                lenderCreditPercentage={
                                                                    branchLenderCreditPercentage
                                                                }
                                                                lenderCreditAmount={
                                                                    lenderCreditAmount
                                                                }
                                                                realtorCommissionPercentage={
                                                                    branchRealtorCommissionPercentage
                                                                }
                                                                realtorCommissionAmount={
                                                                    realtorCommissionAmount
                                                                }
                                                                realtorCreditPercentage={
                                                                    branchRealtorCreditPercentage
                                                                }
                                                                realtorCreditAmount={
                                                                    realtorCreditAmount
                                                                }
                                                                totalCredit={
                                                                    totalCredit
                                                                }
                                                                handlePurchasePriceFieldValueChanged={
                                                                    handleChangePurchasePrice
                                                                }
                                                                handleDownPaymentFieldValueChanged={
                                                                    handleChangeDownPayment
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span className={styles.customArrow} />
                        </div>
                    </div>
                    <span className={styles.contactUsSeparator} />
                    <div
                        className={contactUsComment}
                        style={{ marginBottom: "auto" }}>
                        <h1 id="potential-savings" className={styles.title}>
                            {title}
                        </h1>
                        <Accent align="left" />
                        <HTMLContent content={html} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default G2HCalculatorModule;
