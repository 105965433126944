import { cloneDeep } from "lodash";
import defaultSocialMedia from "../../../lib/defaultSocialMedia.json";
import { timestampNow } from "../../../config/cms.config";

const getDefaultTextById = (id, defaultTextItems = []) => {
    const defaultText =
        defaultTextItems.find(item => item.id === id)?.text ?? "";
    return defaultText;
};

export const defaultCollectionState = {
    "loan-officer": {
        loaded: false,
        meta: {
            label: "Loan Officers",
            labelSingular: "Loan Officer",
        },
        published: [],
        draft: [],
        filter: null,
    },
    team: {
        loaded: false,
        meta: {
            label: "Teams",
            labelSingular: "Team",
        },
        published: [],
        draft: [],
        filter: null,
    },
    branch: {
        loaded: false,
        meta: {
            label: "Branches",
            labelSingular: "Branch",
        },
        published: [],
        draft: [],
        filter: null,
    },
    "non-originator": {
        loaded: false,
        meta: {
            label: "Non-Originators",
            labelSingular: "Non-Originator",
        },
        published: [],
        draft: [],
        filter: null,
    },
    blog: {
        loaded: false,
        meta: {
            label: "Loan Officer Blog Posts",
            labelSingular: "Post",
        },
        published: [],
        draft: [],
        filter: {
            field: "type",
            operator: "==",
            value: "individual",
        },
    },
    corporateBlog: {
        loaded: false,
        meta: {
            label: "Corporate Blog Posts",
            labelSingular: "Post",
        },
        published: [],
        draft: [],
        filter: {
            field: "type",
            operator: "==",
            value: "corporate",
        },
    },
    "giving-to-heroes": {
        loaded: false,
        meta: {
            label: "Giving To Heroes",
            labelSingular: "Page",
        },
        published: [],
        draft: [],
        filter: null,
    },
    "corporate-care": {
        loaded: false,
        meta: {
            label: "Corporate Care",
            labelSingular: "Page",
        },
        published: [],
        draft: [],
        filter: null,
    },
    "community-care": {
        loaded: false,
        meta: {
            label: "Community Care",
            labelSingular: "Page",
        },
        published: [],
        draft: [],
        filter: null,
    },
    splash: {
        loaded: false,
        meta: {
            label: "Splash Pages",
            labelSingular: "Page",
        },
        published: [],
        draft: [],
        filter: null,
    },
};

export const collectionMap = {
    "loan-officer": "loan-officer",
    team: "team",
    branch: "branch",
    "non-originator": "non-originator",
    blog: "blog",
    corporateBlog: "blog",
    "giving-to-heroes": "giving-to-heroes",
    "corporate-care": "corporate-care",
    "community-care": "community-care",
    splash: "splash",
};

export const collectonToBodyLabel = {
    "loan-officer": "About you",
    team: "About you",
    branch: "About you",
    blog: "Blog",
    corporateBlog: "Blog",
};

const defaultEvent = {
    eventName: "",
    eventDate: timestampNow(),
    eventTime: "",
    eventLocation: "",
    eventImage:
        "https://ucarecdn.com/6d8ff3a9-717b-4956-ab2d-ebab3cbe0a97/eventtakeaseat.jpg",
    eventCustomImage: "",
    eventDescription: "",
    eventUrl: "",
    eventCta: "",
};

function defaultVideo(defaultTextItems) {
    const returnValue = {
        videoTitle: getDefaultTextById("widget-video-title", defaultTextItems),
        videoCaption: getDefaultTextById(
            "widget-video-caption",
            defaultTextItems,
        ),
        videoUrl: getDefaultTextById("widget-video-url", defaultTextItems),
    };
    return returnValue;
}

export const modules = defaultTextItems => {
    const returnValue = [
        {
            type: "contactUs",
            display: false,
            contactUsTitle: "",
            contactUsHtml: "",
            darkTitleText: true,
            backgroundImage: "blurredMotion",
            backgroundImageCustom: "",
            contactUsLeadEmailCcList: "",
            contactUsConfirmEmailFrom: "",
            contactUsConfirmEmailText: getDefaultTextById(
                "widget-contact-us-confirmation-email-text",
                defaultTextItems,
            ),
            contactUsBammUrl: "",
            contactFormTitle: getDefaultTextById(
                "widget-contact-us-form-title",
                defaultTextItems,
            ),
            contactFormFields: [
                {
                    type: "firstName",
                    required: false,
                },
                {
                    type: "lastName",
                    required: false,
                },
                {
                    type: "phone",
                    required: false,
                },
                {
                    type: "email",
                    required: false,
                },
                {
                    type: "state",
                    required: true,
                },
            ],
        },
        {
            type: "profile",
            display: true,
            profileTitle: "",
            profileBody: "",
            profileShowCompactView: false,
            useBackgroundImage: false,
            backgroundImage: "",
            backgroundImageCustom: "",
            darkTitleText: true,
            overrideAddress: false,
            overrideAddressText: "",
            licensedInText: "",
        },
        {
            type: "content",
            display: false,
            contentImage:
                "https://ucarecdn.com/bc769bdc-90fa-40bb-a196-dce73a51de42/-/resize/943x674/-/preview/-/format/auto/-/quality/smart/houseandkey.jpg",
            contentTitle: "",
            contentText: "",
        },
        {
            type: "teamMembers",
            display: false,
            backgroundImage: "teamMembers",
            backgroundImageCustom: "",
        },
        {
            type: "map",
            display: true,
        },
        {
            type: "calculator",
            display: true,
            showCalculatorHomeAffordability: false,
            showCalculatorMortgage: false,
            showCalculatorRefinance: false,
            showCalculatorRentVsBuy: false,
            showCalculatorRequiredIncome: false,
        },
        {
            type: "video",
            display: true,
            videos: [Object.assign({}, defaultVideo(defaultTextItems))],
            backgroundImageCustom: "",
            darkTitleText: true,
        },
        {
            type: "blog",
            display: true,
        },
        {
            type: "facebookpage",
            display: false,
            facebookPageUrl: defaultSocialMedia.facebook,
        },
        {
            type: "bookings",
            display: false,
            backgroundImage: null,
            darkTitleText: false,
        },
        {
            type: "calendar",
            display: false,
            backgroundImage: null,
            darkTitleText: false,
        },
        {
            type: "instagram",
            display: false,
        },
        {
            type: "resources",
            display: false,
            resourceIntro: getDefaultTextById(
                "widget-resources-intro",
                defaultTextItems,
            ),
        },
        {
            type: "twitter",
            display: false,
            twitter: defaultSocialMedia.twitter,
        },
        {
            type: "events",
            display: false,
            event1: Object.assign({}, defaultEvent),
            event2: Object.assign({}, defaultEvent),
            event3: Object.assign({}, defaultEvent),
        },
        {
            type: "awards",
            display: false,
        },
        {
            type: "testimonials",
            display: false,
        },
        {
            type: "homebot",
            display: false,
            id: "",
        },
    ];
    return returnValue;
};

export const g2hModules = defaultTextItems => [
    {
        type: "aboutG2H",
        display: true,
        aboutG2HHtml: getDefaultTextById(
            "widget-about-g2h-html",
            defaultTextItems,
        ),
        aboutG2HCobrandUrl: "",
        aboutG2HIndustryIcons: [
            { type: "lawEnforcement" },
            { type: "military" },
            { type: "medical" },
            { type: "firefighters" },
            { type: "education" },
        ],
    },
    {
        type: "g2hCalculator",
        display: true,
        g2hCalculatorTitle: getDefaultTextById(
            "widget-g2h-calculator-title",
            defaultTextItems,
        ),
        g2hCalculatorText: getDefaultTextById(
            "widget-g2h-calculator-text",
            defaultTextItems,
        ),
        darkTitleText: true,
        backgroundImage: "whiteWood",
        backgroundImageCustom: "",
        g2hCalculatorFormTitle: getDefaultTextById(
            "widget-g2h-calculator-form-title",
            defaultTextItems,
        ),
        g2hCalculatorBammUrl: "",
        g2hCalculatorFormFields: [
            {
                type: "purchasePrice",
                required: true,
            },
            {
                type: "downPayment",
                required: true,
            },
            {
                type: "firstName",
                required: true,
            },
            {
                type: "lastName",
                required: true,
            },
            {
                type: "email",
                required: true,
            },
            {
                type: "phone",
                required: true,
            },
            {
                type: "state",
                required: true,
            },
        ],
        lenderCreditPercentage: 0.0025,
        realtorCommissionPercentage: 0.025,
        realtorCreditPercentage: 0.1,
    },
    {
        type: "aboutBE",
        display: true,
        aboutBEHtml: getDefaultTextById(
            "widget-about-be-html",
            defaultTextItems,
        ),
        aboutBEImage: "",
        backgroundImage: "blueMosaic",
        backgroundImageCustom: "",
        darkTitleText: false,
    },
    {
        type: "customTestimonials",
        display: false,
        backgroundImage: "testimonialsFlag",
        backgroundImageCustom: "",
        customTestimonials: [
            {
                text: "",
                name: "",
                title: "",
                city: "",
                state: "",
            },
        ],
    },
    {
        type: "becomePartner",
        display: false,
        backgroundImage: "backgroundKey",
        backgroundImageCustom: "",
    },
];

export const careModules = defaultTextItems => [
    {
        type: "aboutCare",
        display: true,
        aboutCareHtml: getDefaultTextById(
            "widget-about-care-html",
            defaultTextItems,
        ),
        backgroundImage: "aboutCare",
        backgroundImageCustom: "",
        darkTitleText: true,
        bammUrl: "",
        contactFormFields: [
            {
                type: "firstName",
                required: true,
            },
            {
                type: "lastName",
                required: true,
            },
            {
                type: "email",
                required: true,
            },
            {
                type: "phone",
                required: true,
            },
            {
                type: "state",
                required: true,
            },
            {
                type: "loanPurpose",
                required: true,
            },
            {
                type: "purchasePrice",
                required: true,
            },
            {
                type: "downPayment",
                required: true,
            },
            {
                type: "loanBalance",
                required: true,
            },
            {
                type: "cashoutAmount",
                required: true,
            },
        ],
        contactFormTitle: getDefaultTextById(
            "widget-about-care-form-title",
            defaultTextItems,
        ),
        contactFormSubTitle: getDefaultTextById(
            "widget-about-care-form-subtitle",
            defaultTextItems,
        ),
        lenderCreditPercentage: 0.0025,
        realtorCommissionPercentage: 0.025,
        realtorCreditPercentage: 0.25,
        noAppraisalFeeCredit: false,
        allowDiscountedRealtorFee: false,
    },
    {
        type: "careTestimonials",
        display: true,
        careTestimonials: [
            {
                text: "",
                name: "",
                image: "",
            },
        ],
    },
    {
        type: "aboutBE",
        display: true,
        aboutBEHtml: getDefaultTextById(
            "widget-about-be-html",
            defaultTextItems,
        ),
        aboutBEImage: "",
        backgroundImage: "blueMosaic",
        backgroundImageCustom: "",
        darkTitleText: false,
    },
];

export const licence = {
    license: "NMLS ID",
    id: "555555",
};

export const staff = {
    name: "",
    photo: "",
    title: "",
    license: "",
    email: "",
};

export const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansa",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
];

export const blogCategories = [
    "All About BE",
    "First-Time Homebuyer",
    "Current Homeowner",
    "Resources",
    "DIY Projects",
    "Beyond BE",
];

export const blogTags = [
    "Tools and Resources",
    "Refinance",
    "Home Loan",
    "Specialty Loans",
    "About Us",
    "Getting Started",
    "Fixed Rate Mortgages",
    "Adjustable Rate Mortgages",
    "FHA Loans",
    "VH Loans",
    "VA Refinance (IRRRL)",
    "Enhanced LTV Mortgage",
    "Reverse Mortgage",
    "Jumbo Loans",
    "USDA Loans",
    "Down Payment Assistance Products",
];

export const heroPhotoAlignment = "center";

export const galleryPhoto =
    "https://ucarecdn.com/b2aaa0cb-d242-48d7-ac87-6cc18f55d6ba/-/preview/200x200/be-circle-mark.png";

const getDefaultLOModules = defaultTextItems => {
    // set profile widget's body text
    let profileBody = getDefaultTextById(
        "widget-profile-lo-bio",
        defaultTextItems,
    );
    let loWidgets = cloneDeep(Object.assign([], modules(defaultTextItems)), {});

    loWidgets = loWidgets.map(mod => {
        if (mod.type === "profile") mod.profileBody = profileBody;

        return mod;
    });

    return loWidgets;
};

export const loanOfficer = defaultTextItems => {
    return {
        title: "",
        templateKey: "loan-officer",
        owner: "",
        urlslug: "",
        applyNow: "",
        applyNowScript: "",
        logIn: "",
        hidden: true,
        disclaimer: true,
        profile: {
            name: "",
            email: "",
            jobTitle: "Senior Loan Officer",
            branch: null,
            licenseIds: [{ license: "", id: "" }],
            licenseLink: null,
        },
        hero: {
            heroBackground: "",
            quote: "",
            byline: "",
            photoAlignment: heroPhotoAlignment,
        },
        gallery: {
            photo: galleryPhoto,
            image1: null,
            image2: null,
            image3: null,
        },
        contact: {
            facebook: defaultSocialMedia.facebook,
            linkedin: defaultSocialMedia.linkedin,
            youtube: defaultSocialMedia.youtube,
            instagram: defaultSocialMedia.instagram,
            twitter: defaultSocialMedia.twitter,
            phone: "",
            mobilePhone: "",
            fax: "",
        },
        modules: getDefaultLOModules(defaultTextItems),
        changelog: "",
        headBeginScript: "",
        headEndScript: "",
        bodyBeginScript: "",
        bodyEndScript: "",
        searchVisibility: [],
    };
};

const getDefaultTeamModules = defaultTextItems => {
    // set profile widget's body text
    let profileBody = getDefaultTextById(
        "widget-profile-team-bio",
        defaultTextItems,
    );
    let teamWidgets = cloneDeep(
        Object.assign([], modules(defaultTextItems)),
        {},
    );

    teamWidgets = teamWidgets.map(mod => {
        if (mod.type === "profile") mod.profileBody = profileBody;

        return mod;
    });

    return teamWidgets;
};

export const team = defaultTextItems => {
    return {
        title: "",
        templateKey: "team",
        owner: "",
        urlslug: "",
        applyNow: "",
        applyNowScript: "",
        logIn: "",
        hidden: true,
        disclaimer: true,
        profile: {
            name: "",
            logo: galleryPhoto,
            graphic: null,
            email: "",
            branch: null,
            licenseIds: [{ license: "", id: "" }],
        },
        hero: {
            heroBackground: "",
            quote: "",
            byline: "",
            photoAlignment: heroPhotoAlignment,
        },
        allowLink: false,
        managers: [],
        loanOfficers: [],
        staff: [],
        contact: {
            facebook: defaultSocialMedia.facebook,
            linkedin: defaultSocialMedia.linkedin,
            youtube: defaultSocialMedia.youtube,
            instagram: defaultSocialMedia.instagram,
            twitter: defaultSocialMedia.twitter,
            phone: "",
            mobilePhone: "",
            fax: "",
        },
        modules: getDefaultTeamModules(defaultTextItems),
        changelog: "",
        headBeginScript: "",
        headEndScript: "",
        bodyBeginScript: "",
        bodyEndScript: "",
    };
};

const getDefaultBranchModules = defaultTextItems => {
    // set profile widget's body text
    let profileBody = getDefaultTextById(
        "widget-profile-branch-bio",
        defaultTextItems,
    );
    let branchModules = cloneDeep(
        Object.assign([], modules(defaultTextItems)),
        {},
    );

    branchModules = branchModules.map(mod => {
        if (mod.type === "profile") mod.profileBody = profileBody;

        return mod;
    });

    return branchModules;
};

export const branch = defaultTextItems => {
    return {
        title: "",
        templateKey: "branch",
        owner: "",
        urlslug: "",
        dbaLogoBlack: "",
        hidden: true,
        disclaimer: true,
        hero: {
            heroBackground: "",
            customPhoto: "",
            photoAlignment: heroPhotoAlignment,
        },
        managers: [],
        loanOfficers: [],
        teams: [],
        staff: [],
        email: "",
        phone: "",
        mobilePhone: "",
        fax: "",
        address1: "",
        address2: "",
        address3: "",
        city: "",
        state: "",
        zip: "",
        lat: 0,
        lon: 0,
        modules: getDefaultBranchModules(defaultTextItems),
        changeLog: "",
        changelog: "",
        headBeginScript: "",
        headEndScript: "",
        bodyBeginScript: "",
        bodyEndScript: "",
    };
};

export const blog = {
    title: "",
    type: "individual",
    author: null,
    metadata: "",
    templateKey: "blog",
    featured: false,
    date: timestampNow(),
    categories: [],
    bloghero: "",
    image: "",
    tags: [],
    body: "",
};

export const corporateBlog = {
    title: "",
    type: "corporate",
    author: "bayequity",
    metadata: "",
    templateKey: "blog",
    featured: false,
    date: timestampNow(),
    categories: [],
    bloghero: "",
    image: "",
    tags: [],
    body: "",
};

export const realtor = {
    name: "",
    title: "",
    title2: "",
    licenseNumber: "",
    phone: "",
    fax: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    url: "",
    photoUrl: "",
    logoUrl: "",
    isDemoRealtor: false,
};

export const nonOriginator = () => {
    return {
        title: "",
        owner: "",
        hidden: false,
        profile: {
            name: "",
            email: "",
            jobTitle: "",
            licenseIds: [{ license: "", id: "" }],
            branch: {
                dbaLogoBlack: "",
                address1: "",
                address2: "",
                address3: "",
                city: "",
                state: "",
                zip: "",
                lat: 0,
                lon: 0,
            },
        },
        gallery: {
            photo: galleryPhoto,
        },
        contact: {
            facebook: defaultSocialMedia.facebook,
            linkedin: defaultSocialMedia.linkedin,
            youtube: defaultSocialMedia.youtube,
            instagram: defaultSocialMedia.instagram,
            twitter: defaultSocialMedia.twitter,
            phone: "",
            mobilePhone: "",
            fax: "",
        },
    };
};

export const getDefaultG2HModules = defaultTextItems => {
    // set profile widget's body text
    let profileBody = "";
    let g2HWidgets = cloneDeep(
        Object.assign([], g2hModules(defaultTextItems)),
        {},
    );
    let otherWidgets = cloneDeep(
        Object.assign([], modules(defaultTextItems)),
        {},
    );
    let widgets = g2HWidgets.concat(otherWidgets);

    let displayedWidgets = ["aboutG2H", "g2hCalculator", "aboutBE", "profile"];

    widgets = widgets.map(mod => {
        if (mod.type === "profile") {
            mod.profileBody = profileBody;
            mod.profileShowCompactView = true;
        }

        if (!displayedWidgets.includes(mod.type)) mod.display = false;

        return mod;
    });

    return widgets;
};

export const g2HPage = defaultTextItems => {
    return {
        title: "",
        urlslug: "",
        hidden: true,
        disclaimer: true,
        email: "",
        cobrandUrl: "",
        loanOfficer: null,
        team: null,
        realtor,
        lenderCreditPercentage: 0.0025,
        realtorCommissionPercentage: 0.025,
        realtorCreditPercentage: 0.1,
        headBeginScript: "",
        headEndScript: "",
        bodyBeginScript: "",
        bodyEndScript: "",
        hero: {
            heroBackground: "americanFlag",
            customPhoto: "",
            photoAlignment: heroPhotoAlignment,
            title: getDefaultTextById("g2h-hero-title", defaultTextItems),
            html: getDefaultTextById("g2h-hero-html", defaultTextItems),
            darkText: false,
        },
        modules: getDefaultG2HModules(defaultTextItems),
    };
};

export const getDefaultCareModules = (
    defaultTextItems,
    allowDiscountedRealtorFee = false,
) => {
    // set profile widget's title and body text
    let profileTitle = getDefaultTextById(
        "widget-profile-care-title",
        defaultTextItems,
    );
    let profileBody = getDefaultTextById(
        "widget-profile-care-bio",
        defaultTextItems,
    );
    let careWidgets = cloneDeep(
        Object.assign([], careModules(defaultTextItems)),
        {},
    );
    let otherWidgets = cloneDeep(
        Object.assign([], modules(defaultTextItems)),
        {},
    );
    let widgets = careWidgets.concat(otherWidgets);

    let displayedWidgets = [
        "aboutCare",
        "careTestimonials",
        "aboutBE",
        "profile",
    ];

    widgets = widgets.map(mod => {
        if (mod.type === "profile") {
            mod.profileTitle = profileTitle;
            mod.profileBody = profileBody;
            mod.profileShowCompactView = true;
        }

        // set allowDiscountedRealtorFee value
        if (mod.type === "aboutCare") {
            mod.allowDiscountedRealtorFee = allowDiscountedRealtorFee;
        }

        if (!displayedWidgets.includes(mod.type)) mod.display = false;

        return mod;
    });

    return widgets;
};

export const corporateCarePage = defaultTextItems => {
    return {
        title: "",
        urlslug: "",
        hidden: true,
        disclaimer: true,
        companyLogo: "",
        companyPhoto: "",
        loanOfficer: null,
        team: null,
        realtor,
        body: "",
        messageFrom: "",
        hero: {
            heroBackground: "careHeaderBackground",
            customPhoto: "",
            photoAlignment: heroPhotoAlignment,
        },
        modules: getDefaultCareModules(defaultTextItems),
    };
};

export const communityCarePage = defaultTextItems => {
    return {
        title: "",
        urlslug: "",
        hidden: true,
        disclaimer: true,
        companyLogo: "",
        companyPhoto: "",
        loanOfficer: null,
        team: null,
        body: "",
        messageFrom: "",
        hero: {
            heroBackground: "careHeaderBackground",
            customPhoto: "",
            photoAlignment: heroPhotoAlignment,
        },
        modules: getDefaultCareModules(defaultTextItems, true),
    };
};

const getDefaultSplashModules = defaultTextItems => {
    // hide all widgets by default for Splash pages, except for those whose type is in enabledWidgets
    let enabledWidgets = ["contactUs", "profile"];
    let splashModules = cloneDeep(
        Object.assign([], modules(defaultTextItems)),
        {},
    );

    splashModules = splashModules.map(mod => {
        if (enabledWidgets.includes(mod.type)) mod.display = true;
        else mod.display = false;

        if (mod.type === "profile")
            mod.profileBody =
                "The lending professionals at Bay Equity Home Loans are dedicated to helping you will all your home mortgage needs.\n\nWith our constantly changing housing and mortgage markets, we can help you navigate the many options to find the right loan to fit your family, your needs and your financial circumstances.\n\nWhether you are buying or refinancing, buying your first home or building your dream home, we can help you explore the many options for financing.";

        return mod;
    });

    return splashModules;
};

export const splashPage = defaultTextItems => {
    return {
        title: "",
        vanityUrl: "",
        email: "none",
        urlslug: "",
        hidden: true,
        disclaimer: true,
        dbaLogoBlack: "",
        headerImage: "couple",
        headerImageCustom: "",
        headerImageAlignment: "center",
        headerTitle: "Refinancing\nMade Simple!",
        headerSubTitle: "",
        headerDarkText: false,
        displayApplyNow: true,
        loanOfficer: null,
        branch: null,
        team: null,
        nonOriginator: null,
        modules: getDefaultSplashModules(defaultTextItems),
        headBeginScript: "",
        headEndScript: "",
        bodyBeginScript: "",
        bodyEndScript: "",
    };
};

export const newEntries = defaultTextItems => {
    return {
        "loan-officer": loanOfficer(defaultTextItems),
        team: team(defaultTextItems),
        branch: branch(defaultTextItems),
        "non-originator": nonOriginator(defaultTextItems),
        blog,
        corporateBlog,
        "giving-to-heroes": g2HPage(defaultTextItems),
        "corporate-care": corporateCarePage(defaultTextItems),
        "community-care": communityCarePage(defaultTextItems),
        splash: splashPage(defaultTextItems),
    };
};
