import React, { PureComponent } from "react";
import getSurveyData from "../../scripts/experience";

function withSurvey(WrappedComponent) {
    return class extends PureComponent {
        state = {
            testimonials: [],
            stars: null,
            ratingsCount: 0,
            isSurveyInit: false,
            hasSurveyErrored: false,
            shouldDisplaySurvey: false,
        };
        async componentDidMount() {
            const { modules } = this.props;
            const { social_id, display } =
                (modules &&
                    modules.find(module => module.type === "testimonials")) ||
                {};
            if (!social_id) {
                return;
            }

            const { ratings, testimonials, error } = await getSurveyData(
                social_id,
            );
            if (error) {
                return this.setState({ hasSurveyErrored: true });
            }
            this.setState({
                stars: ratings.avg || null,
                ratingsCount: ratings.count || 0,
                testimonials,
                isSurveyInit: true,
                shouldDisplaySurvey: display,
            });
        }
        async componentDidUpdate(prevProps) {
            const { social_id } = this.props;
            if (!social_id) {
                return;
            }
            if (social_id !== prevProps.social_id) {
                const { ratings, testimonials, error } = await getSurveyData(
                    social_id,
                );
                if (error) {
                    return this.setState({ hasSurveyErrored: true });
                }
                this.setState({
                    stars: ratings.avg || null,
                    ratingsCount: ratings.count || 0,
                    testimonials,
                    isSurveyInit: true,
                });
            }
        }
        render() {
            return <WrappedComponent {...this.props} {...this.state} />;
        }
    };
}

export default withSurvey;
