import React from "react";

import Accent from "../../../Accent";

import styles from "./styles.module.less";

const VideoSingle = ({
    URL,
    title,
    caption,
    backgroundImageCustom,
    darkTitleText,
    photoAlignment,
}) => {
    var src = "";
    var srcBase = "";
    var videoId = "";

    if (URL.includes("youtu")) {
        videoId = "";

        if (URL.includes("watch?v=")) {
            videoId = URL.split("v=")[1];
        } else {
            videoId = URL.split("be/")[1];
        }

        srcBase = "https://www.youtube.com/embed/";
        src = srcBase + videoId;
    } else if (URL.includes("vimeo")) {
        srcBase = "https://player.vimeo.com/video/";
        videoId = URL.split(".com/")[1];
        src = srcBase + videoId;
    } else if (URL.includes("facebook.com")) {
        srcBase =
            "https://www.facebook.com/plugins/video.php?height=314&show_text=false&width=560&href=";
        src = srcBase + encodeURI(URL);
    }

    var ampersandPosition = videoId.indexOf("&");
    if (ampersandPosition !== -1) {
        videoId = videoId.substring(0, ampersandPosition);
    }

    let style = {
        backgroundPosition: photoAlignment,
    };
    if (!!backgroundImageCustom)
        style.backgroundImage = `url("${backgroundImageCustom}")`;
    let textStyle = !darkTitleText ? { color: "white" } : {};

    return (
        <section className={styles.Video} style={style} data-background="light">
            <div className={styles.inner}>
                <div className={styles.container}>
                    <div className={styles.videoContainer}>
                        <iframe
                            src={src}
                            frameBorder="0"
                            title="Video"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                    <div className={styles.copy}>
                        <h1 className={styles.title} style={textStyle}>
                            {title}
                        </h1>
                        <Accent align="left" />
                        <div className={styles.body} style={textStyle}>
                            {caption}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default VideoSingle;
