import React from "react";

import styles from "./styles.module.less";

const TeamLink = ({ team }) => {
    if (!team) return null;
    const { slug = "", title = "" } = team;
    if (!slug || !title) return null;
    return (
        <div>
            <p className={styles.teamLink}>
                <a href={slug}>{title}</a>
            </p>
        </div>
    );
};

export default TeamLink;
