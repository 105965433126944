import React from "react";
import { HTMLContent } from "../Content";
import Accent from "../Accent";

import styles from "./styles.module.less";

const Content = ({
    img,
    title,
    html,
    alt = "Real Estate - Bay Equity Home Loans",
}) => {
    const defaultImage = `https://ucarecdn.com/bc769bdc-90fa-40bb-a196-dce73a51de42/Housekeyinhand821895542_4836x3456min.jpeg`;

    return (
        <section className={styles.Content}>
            <div className={styles.container} style={{ flexDirection: "row" }}>
                <div className={styles.image}>
                    <img
                        className={styles.icon}
                        data-blink-src={!!img ? img : defaultImage}
                        alt={alt}
                    />
                </div>
                <div className={styles.text}>
                    <h1 className={styles.title}>{title}</h1>
                    <Accent align="left" />
                    <HTMLContent content={html} />
                </div>
            </div>
        </section>
    );
};

export default Content;
