import React from "react";
import { get, isEmpty } from "lodash";
import ContactInfo from "../ContactInfo";
import TeamLink from "../TeamLink";
import StarRatings from "../../../StarRatings";
import RealtorView from "../RealtorView";

import Gallery from "../../../Gallery";
import SocialIcons from "../../../SocialIcons";
import LinkButton from "../../../LinkButton";

import { HTMLContent } from "../../../Content";

import defaultLogo from "../../../Header/images/be_logo_black.png";

import { getApplyUrl } from "../../../../lib/helpers";

import styles from "./styles.module.less";

const CompactView = ({
    applyNow = "",
    applyNowScript = "",
    slug = "",
    jobTitle,
    name,
    title,
    body,
    modules = [],
    branch = {},
    team = {},
    licenseIds = [],
    socialIcons = [],
    imageList,
    stars,
    ratingsCount,
    isReviewInit,
    shouldDisplaySurvey,
    templateKey,
    contactInfo = {},
    darkText,
    realtor,
    searchVisibility,
    overrideAddress = false,
    overrideAddressText = "",
    licensedInText = "",
    licenseLink,
}) => {
    let siteUrl = `https://bayequityhomeloans.com${slug}`;

    // const showGallery = !["team", "branch"].includes(templateKey);
    const showGallery = true;
    const logoPath = get(branch, "dbaLogoBlack", null) || defaultLogo;
    const titleClass = darkText
        ? styles.title
        : `${styles.title} ${styles.titleLight}`;

    return (
        <div className={`${styles.root} ${styles.mobile}`}>
            {title && <div className={titleClass}>{title}</div>}
            {body && (
                <div className={styles.description}>
                    <HTMLContent content={body} />
                    {templateKey === "team" && !!branch && (
                        <div>
                            <p className={styles.branchLink}>
                                <a href={branch.slug}>{branch.title}</a>
                            </p>
                        </div>
                    )}
                </div>
            )}
            <div className={styles.logoContainer}>
                {showGallery && (
                    <div className={styles.gallery}>
                        <Gallery
                            imageList={imageList.filter(Boolean)}
                            alt={name}
                        />
                    </div>
                )}
                <div className={styles.shortInfo}>
                    <div className={styles.shortInfoContent}>
                        <div className={styles.shortInfoWrapper}>
                            <h1 className={styles.h2}>{name}</h1>
                            <div className={styles.shortInfoSecond}>
                                <h2 className={styles.firstChild}>
                                    {jobTitle}
                                </h2>
                                <h3 className={styles.lastChild}>
                                    {licenseIds.map((l, i) => {
                                        return (
                                            !!l.license && (
                                                <span
                                                    className={
                                                        styles.licenseeIds
                                                    }
                                                    key={l.license + l.id + i}>
                                                    {l.license}# {l.id}
                                                </span>
                                            )
                                        );
                                    })}
                                </h3>
                                {licenseLink && (
                                    <div className={styles.licenseLink}>
                                        State Licenses:
                                        <a
                                            href={licenseLink}
                                            target="_blank"
                                            rel="noreferrer">
                                            Click Here
                                        </a>
                                    </div>
                                )}
                            </div>
                            <div className={styles.infoContainer}>
                                <div className={styles.moreInfo}>
                                    <ContactInfo
                                        {...contactInfo}
                                        overrideAddress={overrideAddress}
                                        overrideAddressText={
                                            overrideAddressText
                                        }
                                    />
                                    {licensedInText && (
                                        <div
                                            style={{
                                                whiteSpace: "break-spaces",
                                            }}>
                                            Licensed in {licensedInText}
                                        </div>
                                    )}
                                    {isEmpty(team) &&
                                        templateKey !== "non-originator" && (
                                            <div className={styles.ownerLink}>
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={siteUrl}>
                                                    {siteUrl.replace(
                                                        /^https?:\/\//i,
                                                        "",
                                                    )}
                                                </a>
                                            </div>
                                        )}
                                    <div className={styles.iconContainer}>
                                        <SocialIcons
                                            {...socialIcons}
                                            darkText={darkText}
                                        />
                                    </div>
                                    <TeamLink team={team} />
                                </div>
                            </div>
                        </div>

                        <div className={styles.ctaContainer}>
                            {!!applyNow && (
                                <div className={styles.applyNowContainer}>
                                    <div className={styles.starButton}>
                                        <LinkButton
                                            label="Apply Now / Log In"
                                            href={applyNow}
                                            target="_blank"
                                        />
                                    </div>
                                    {isReviewInit &&
                                        shouldDisplaySurvey &&
                                        stars && (
                                            <div className={styles.starRatings}>
                                                <StarRatings
                                                    modules={modules}
                                                    stars={stars}
                                                    ratingsCount={ratingsCount}
                                                />
                                            </div>
                                        )}
                                    <div className={styles.starLogo}>
                                        <img src={logoPath} alt="Bay Equity" />
                                    </div>
                                </div>
                            )}
                            {!!!applyNow && (
                                <div className={styles.nonOriginatorContainer}>
                                    <img src={logoPath} alt="Bay Equity" />
                                    {logoPath === defaultLogo && (
                                        <>
                                            <p>We're here to get you home.</p>
                                            <a href="https://bayequityhomeloans.com">
                                                www.BayEquityHomeLoans.com
                                            </a>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {realtor && (
                <div className={styles.realtor}>
                    <RealtorView
                        name={realtor.name}
                        jobTitle={!!realtor.title2 ? realtor.title2 : "Realtor"}
                        licenseIds={[
                            {
                                license: "License",
                                id: realtor.licenseNumber,
                            },
                        ]}
                        image={realtor.photoUrl}
                        logo={realtor.logoUrl}
                        contactInfo={realtor}
                        showCompactView={true}
                    />
                </div>
            )}
        </div>
    );
};

export default CompactView;
