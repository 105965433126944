import React from "react";

import Accent from "../Accent";
import { HTMLContent } from "../Content";

import predefinedIcons from "./lib/predefinedIcons.json";

import styles from "./styles.module.less";

const AboutG2HModule = ({ html, cobrandUrl, icons = [] }) => {
    return (
        <section>
            <div className={styles.inner}>
                <div id="aboutgth" className={styles.aboutgth}>
                    <div className={styles.row}>
                        <h1
                            id="about-the-program"
                            className={styles.sectionTitle}>
                            Giving to Heroes
                        </h1>
                        <Accent align="center" />
                        <div className={styles.fadedback}>
                            <div className={styles.textContainer}>
                                <HTMLContent
                                    className={styles.textblurb}
                                    content={html}
                                />
                                {cobrandUrl && (
                                    <div
                                        className={
                                            styles.cobrandImageContainer
                                        }>
                                        <img
                                            src={cobrandUrl}
                                            className={styles.cobrandImage}
                                            alt=""
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={styles.row}>
                                <div className={styles.industryIconsContainer}>
                                    {icons &&
                                        icons.map((icon, i) => {
                                            if (icon.type) {
                                                if (icon.type !== "custom") {
                                                    return (
                                                        <Icon
                                                            key={i}
                                                            iconUrl={
                                                                predefinedIcons
                                                                    .icons[
                                                                    icon.type
                                                                ].iconUrl
                                                            }
                                                            label={
                                                                predefinedIcons
                                                                    .icons[
                                                                    icon.type
                                                                ].label
                                                            }
                                                        />
                                                    );
                                                } else {
                                                    return (
                                                        <Icon
                                                            key={i}
                                                            iconUrl={
                                                                icon.iconUrl
                                                            }
                                                            label={icon.label}
                                                        />
                                                    );
                                                }
                                            } else return;
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const Icon = ({ iconUrl, label }) => {
    return (
        <div className={styles.careerContainer}>
            <img className={styles.careericon} src={iconUrl} alt="" />
            <div className={styles.careertext}>{label}</div>
        </div>
    );
};

export default AboutG2HModule;
