import React from "react";

import styles from "./styles.module.less";
import TestimonialsCarousel from "../TestimonialsCarousel";

const TestimonialsWidget = ({ items = [] }) => {
    const title = "WHAT PEOPLE ARE SAYING";

    if (!items || !items.length) {
        return null;
    }

    return (
        <section className={styles.LessonSlider} data-background="photo">
            <div className={styles.inner}>
                <TestimonialsCarousel title={title} items={items} />
            </div>
        </section>
    );
};

export default TestimonialsWidget;
