import React from "react";

import styles from "./styles.module.less";

const SlideButton = ({ goToSlide, state, index }) => (
    <div
        className={state === "on" ? styles.on : styles.off}
        onClick={() => goToSlide(index)}
    />
);

export default SlideButton;
