import React from "react";
import classnames from "classnames";
import styles from "./styles.module.less";
import heroes from "./heroes.module.less";

const defaultPhoto = "backgroundHouse";

const BecomePartner = ({ backgroundImage, backgroundImageCustom }) => {
    let heroBackground = !!backgroundImage ? backgroundImage : defaultPhoto;
    let cls = classnames(styles.becomepartner, {
        [heroes[heroBackground]]: !backgroundImageCustom,
    });
    let style = {};
    if (!!backgroundImageCustom) {
        style.backgroundImage = `url("${backgroundImageCustom}")`;
    }

    return (
        <div id="becomepartner" className={cls} style={style}>
            <div className={styles.row}>
                <div className={styles.sectionContainer}>
                    <div className={styles.titlebox}>
                        <p className={styles.sectionTitle}>Become a Partner</p>
                    </div>
                    <div className={styles.textblurb}>
                        <p className={styles.sectionText}>
                            If you are interested in becoming a Realtor Partner
                            with Giving to Heroes, please contact your local Bay
                            Equity Branch Manager.
                        </p>
                        <p className={styles.sectionText}>
                            <a
                                className={styles.link}
                                href="http://www.bayequityhomeloans.com">
                                Find your local branch at
                                BayEquityHomeLoans.com.
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BecomePartner;
