import React, { useEffect } from "react";
import Accent from "../Accent";
import LinkButton from "../LinkButton";

// still needs styles
import styles from "./styles.module.less";
import defBackgroundImage from "./images/bookings-background.jpg";
import bookingsImage from "./images/bookings-with-me-image.png";

const Bookings = ({
    name,
    bookings,
    backgroundImage = null,
    darkTitleText = false,
}) => {
    const container = React.createRef();
    const bgImage = backgroundImage ?? defBackgroundImage;
    const titleStyle = !darkTitleText ? { color: "white" } : {};

    if (!bookings) return null;

    return (
        <>
            <section
                id="bookings-schedule"
                ref={container}
                name="schedule-appointment"
                className={styles.component}
                data-background="photo"
                data-blink-src={bgImage}>
                <div className={styles.inner}>
                    <div className={styles.container}>
                        <h1 className={styles.title} style={titleStyle}>
                            Schedule a time to chat{" "}
                        </h1>
                        <Accent align="left" />
                        <div className={styles.bookings}>
                            <div className={styles.rectangleTop} />
                            <div className={styles.bookingsWrapper}>
                                <div className={styles.bookingsBody}>
                                    <div className={styles.imageDiv}>
                                        <img
                                            src={bookingsImage}
                                            className={styles.image}
                                            alt="Calendar Icon"
                                        />
                                    </div>
                                    <div
                                        className={
                                            styles.bookingsNameButtonDiv
                                        }>
                                        <div className={styles.bookingsNameDiv}>
                                            <div
                                                className={styles.bookingsName}>
                                                {name}
                                            </div>
                                            <div
                                                className={
                                                    styles.bookingsSubname
                                                }>
                                                Bookings with me
                                            </div>
                                        </div>
                                        <LinkButton
                                            label="Click here to access my online calendar"
                                            href={bookings}
                                            target="_blank"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.rectangleBottom} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Bookings;
