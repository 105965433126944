import React, { Component, createRef } from "react";
import { chunk } from "lodash";
import Accent from "../Accent";

import Carousel from "../CarouselComponent";

import Resource from "./Components/Resource";

import styles from "./styles.module.less";

class ResourceModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resourcesDivHeight: 0,
        };
        this.resourcesDiv = createRef();
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateHeight);
        this.timeout = setTimeout(() => this.updateHeight(), 1000); // clientHeight is not accurate at mount
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateHeight);
        clearTimeout(this.timeout);
    }

    updateHeight = () => {
        const { resources } = this.props;
        if (!resources || !this.resourcesDiv.current) {
            return;
        }
        this.setState({
            resourcesDivHeight: this.resourcesDiv.current.clientHeight,
        });
    };

    RESOURCES_PER_PAGE = 6;

    _renderResources = () => {
        const { resources } = this.props;
        const { resourcesDivHeight } = this.state;
        /*eslint-disable-next-line no-mixed-operators*/
        const chunkedResources =
            (resources && chunk(resources, this.RESOURCES_PER_PAGE)) || [];
        return chunkedResources.map((chunk, i) => (
            <div
                key={i}
                className={styles.resourcesContainer}
                ref={i === 0 ? this.resourcesDiv : null}
                style={i !== 0 ? { height: `${resourcesDivHeight}px` } : {}}>
                {chunk.map((resource, index) => (
                    <Resource
                        key={index}
                        resource={resource}
                        isSmallGroup={i === 0 && chunk.length <= 3}
                    />
                ))}
            </div>
        ));
    };

    render() {
        const { intro, resources = [] } = this.props;
        const resourcesArr = this._renderResources();
        return (
            <>
                {resources && (
                    <section
                        className={styles.container}
                        data-background="light">
                        <div className={`${styles.inner} ${styles.helpModule}`}>
                            <div className={styles.help}>
                                <div className={styles.content}>
                                    <h1 className={styles.title}>
                                        Need some help?
                                    </h1>
                                    <Accent align="left" />
                                    <div className={styles.copy}>{intro}</div>
                                </div>
                                <div className={styles.carouselContainer}>
                                    <Carousel
                                        componentsArr={resourcesArr}
                                        renderNavButtons
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </>
        );
    }
}

export default ResourceModule;

ResourceModule.defaultProps = {
    intro:
        "That’s why we’re here. Download a variety of helpful documents and resources to make your loan process that much easier.",
};
