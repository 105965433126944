import React, { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import * as d3 from "d3";

import { set, cloneDeep, findIndex, update } from "lodash";
import SimpleReactValidator from "simple-react-validator";

import { HTMLContent } from "../Content";
import CustomForm from "../CustomForm";
import Savings from "./components/CalculatorSavings";

import styles from "./styles.module.less";
import heroes from "./components/Hero/heroes.module.less";
import predefinedInputs from "./lib/predefinedInputs";

const defaultPhoto = `whiteMosaic`;

const AboutCareModule = ({
    firestoreId,
    moduleIndex,
    isPreview,
    html,
    backgroundImage,
    backgroundImageCustom,
    bammUrl,
    fields,
    contactFormTitle,
    contactFormSubtitle,
    darkText,
    noAppraisalFeeCredit,
    allowDiscountedRealtorFee,
    lenderCreditPercentage,
    realtorCreditPercentage,
    realtorCommissionPercentage,
    pageTitle,
    slug,
    leadEmailInfo,
    pageType = "corporate-care",
}) => {
    const aboutCareClass = darkText
        ? `${styles.aboutCare} ${styles.aboutCareDark}`
        : styles.aboutCare;
    let heroBackground = !!backgroundImage ? backgroundImage : defaultPhoto;
    let cls = classnames(styles.widget, {
        [heroes[heroBackground]]: !backgroundImageCustom,
    });
    let style = {};
    if (!!backgroundImageCustom) {
        style.backgroundImage = `url("${backgroundImageCustom}")`;
    }

    const [formValues, setFormValues] = useState({
        botField: "",
        loanPurpose: "",
        disclosure: false,
    });
    const [lenderCreditAmount, setLenderCreditAmount] = useState(0);
    const [appraisalFeeCredit, setAppraisalFeeCredit] = useState(0);
    const [realtorCommissionAmount, setRealtorCommissionAmount] = useState(0);
    const [realtorCreditAmount, setRealtorCreditAmount] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [showSavings, setShowSavings] = useState(false);
    const [netlifyError, setNetlifyError] = useState("");
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [downPayment, setDownPayment] = useState(0);
    const [loanAmount, setLoanAmount] = useState(0);
    const [loanBalance, setLoanBalance] = useState(0);
    const [cashoutAmount, setCashoutAmount] = useState(0);
    const [fieldsWorkingCopy, setFieldsWorkingCopy] = useState(
        cloneDeep(fields),
    );
    const [hiddenFields, setHiddenFields] = useState([
        "purchasePrice",
        "downPayment",
        "loanBalance",
        "cashoutAmount",
    ]);

    useEffect(() => {
        const formValuesTmp = cloneDeep(formValues);
        const errors = {};
        let customCounter = 0;

        fields &&
            fields.forEach(field => {
                if (!field.type) return;

                if (field.type !== "custom") {
                    let inputs = predefinedInputs.fields[field.type].inputs;
                    inputs.forEach(input => {
                        set(formValuesTmp, input.name, "");
                        set(errors, input.name, "");
                    });
                } else {
                    // set name for custom field
                    customCounter += 1;
                    const name = "custom" + customCounter;
                    field.name = name;

                    // set default value
                    let defaultValue = "";

                    if (field.customFieldType === "checkboxList") {
                        defaultValue = {};

                        field.options.forEach(option => {
                            set(defaultValue, option.value, false);
                        });
                    }

                    set(formValuesTmp, name, defaultValue);
                    set(errors, name, "");
                }

                // set disclosure to be unchecked by default
                set(formValuesTmp, "disclosure", "");
            });

        setFormValues(formValuesTmp);
        updateFieldAvailability("");
    }, []);

    useEffect(() => {
        updateFieldAvailability(formValues.loanPurpose);
    }, [fields]);

    const updateFieldAvailability = loanPurpose => {
        let fieldsCopy = cloneDeep(fields);

        // set hidden fields based on value of loanPurpose
        if (loanPurpose === "purchase") {
            setHiddenFields(["loanBalance", "cashoutAmount"]);

            // set required property for conditional fields
            let fieldIndex = findIndex(fieldsCopy, field => {
                return field.type === "loanBalance";
            });
            fieldsCopy[fieldIndex].required = false;
            fieldIndex = findIndex(fieldsCopy, field => {
                return field.type === "cashoutAmount";
            });
            fieldsCopy[fieldIndex].required = false;
            fieldIndex = findIndex(fieldsCopy, field => {
                return field.type === "purchasePrice";
            });
            fieldsCopy[fieldIndex].required = true;
            fieldIndex = findIndex(fieldsCopy, field => {
                return field.type === "downPayment";
            });
            fieldsCopy[fieldIndex].required = true;
        } else if (loanPurpose === "refinance") {
            setHiddenFields(["purchasePrice", "downPayment"]);

            // set required property for conditional fields
            let fieldIndex = findIndex(fieldsCopy, field => {
                return field.type === "loanBalance";
            });
            fieldsCopy[fieldIndex].required = true;
            fieldIndex = findIndex(fieldsCopy, field => {
                return field.type === "cashoutAmount";
            });
            fieldsCopy[fieldIndex].required = true;
            fieldIndex = findIndex(fieldsCopy, field => {
                return field.type === "purchasePrice";
            });
            fieldsCopy[fieldIndex].required = false;
            fieldIndex = findIndex(fieldsCopy, field => {
                return field.type === "downPayment";
            });
            fieldsCopy[fieldIndex].required = false;
        } else {
            setHiddenFields([
                "loanBalance",
                "cashoutAmount",
                "purchasePrice",
                "downPayment",
            ]);

            // set required property for conditional fields
            let fieldIndex = findIndex(fieldsCopy, field => {
                return field.type === "loanBalance";
            });
            fieldsCopy[fieldIndex].required = false;
            fieldIndex = findIndex(fieldsCopy, field => {
                return field.type === "cashoutAmount";
            });
            fieldsCopy[fieldIndex].required = false;
            fieldIndex = findIndex(fieldsCopy, field => {
                return field.type === "purchasePrice";
            });
            fieldsCopy[fieldIndex].required = false;
            fieldIndex = findIndex(fieldsCopy, field => {
                return field.type === "downPayment";
            });
            fieldsCopy[fieldIndex].required = false;
        }

        // reset validator fields so that hidden fields aren't required
        validator.current.purgeFields();

        setFieldsWorkingCopy(fieldsCopy);
    };

    const handleFormSubmit = e => {
        e.preventDefault();
        if (!isPreview) {
            if (validator.current.allValid()) {
                calculateSavings(true);
                setShowSavings(true);
            }
        } else {
            validator.current.showMessage();
        }
    };

    const handleBlur = e => {
        validator.current.showMessageFor(e.target.name);
    };

    const handleChange = e => {
        const { name } = e.target;
        const value =
            e.target.type === "checkbox" ? e.target.checked : e.target.value;

        let formValuesCopy = cloneDeep(formValues);
        set(formValuesCopy, name, value);

        setFormValues(formValuesCopy);

        if (name === "purchasePrice") setPurchasePrice(parseFloat(value));
        if (name === "downPayment") setDownPayment(parseFloat(value));
        if (name === "loanBalance") setLoanBalance(parseFloat(value));
        if (name === "cashoutAmount") setCashoutAmount(parseFloat(value));

        if (name === "loanPurpose")
            updateFieldAvailability(formValuesCopy.loanPurpose);

        if (!isPreview) validator.current.showMessageFor(e.target.name);
    };

    const integrateNetlify = () => {
        const body = new URLSearchParams();
        const formData = [];

        if (pageType === "corporate-care")
            body.append("form-name", process.env.GATSBY_CALCULATOR_FORM_CORP);
        else if (pageType === "community-care")
            body.append("form-name", process.env.GATSBY_CALCULATOR_FORM_COMM);

        body.append("companyName", pageTitle);
        body.append("loName", leadEmailInfo.loanOfficerName);
        body.append("loEmail", leadEmailInfo.email);
        body.append(
            "electronicRecordsConsent",
            formValues.electronicRecordsConsent ? "Yes" : "No",
        );
        body.append("formConsent", formValues.disclosure ? "Yes" : "No");

        fields.forEach(field => {
            if (!!field.type) {
                if (field.type !== "custom") {
                    const fieldInfo = predefinedInputs["fields"][field.type];

                    fieldInfo.inputs.forEach(input => {
                        let fieldData = {};

                        // predefined field - append the value
                        body.append(input.name, formValues[input.name]);
                        let bammValue = "";
                        let displayValue = "";

                        // no predefined fields are checkboxes or radios, so we only need a special case for select inputs
                        if (input.inputType === "select") {
                            input.options.forEach(option => {
                                if (formValues[input.name] === option.value) {
                                    displayValue = option.label;

                                    if (!!input.hasBammField)
                                        bammValue = option.value;
                                }
                            });
                        } else {
                            displayValue = formValues[input.name];

                            if (!!input.hasBammField) bammValue = displayValue;
                        }

                        fieldData["name"] = input.name;
                        fieldData["label"] = input.label;
                        fieldData["value"] = displayValue;
                        fieldData["bammName"] = input.bammName;
                        fieldData["bammValue"] = bammValue;

                        // if both electronicRecordsConsent and disclosure are unchecked, suppress the phone number
                        // if electronicRecordsConsent is checked and disclosure is not, suppress the phone number
                        // if both electronicRecordsConsent and disclosure are checked, send the phone number
                        if (
                            input.bammName === "Cell_Phone" &&
                            !(
                                formValues.electronicRecordsConsent &&
                                formValues.disclosure
                            )
                        ) {
                            fieldData["bammName"] = "";
                            fieldData["bammValue"] = "";
                        }

                        formData.push(fieldData);
                    });
                } else {
                    // custom field - show the value as "'Field Label' = field value"
                    let customLabel = "'" + field.label + "' = ";
                    let bammValue = "";
                    let displayValue = "";
                    let fieldData = {};

                    if (field.customFieldType === "checkboxList") {
                        // checkbox list - show a comma-separated list of the checked items' labels, or "None Selected" if none are checked
                        let selectedLabels = [];

                        field.options.forEach(option => {
                            if (formValues[field.name][option.value] === true) {
                                selectedLabels.push(option.label);
                            }
                        });

                        if (selectedLabels.length === 0) {
                            displayValue = "None selected";
                        } else {
                            displayValue = selectedLabels.join(", ");
                        }

                        body.append(field.name, customLabel + displayValue);

                        // there are no checkbox bamm fields, so just set bammValue to blank
                        bammValue = "";
                    } else if (field.customFieldType === "checkbox") {
                        // checkbox - show "Yes" or "No"
                        if (!!formValues[field.name]) displayValue = "Yes";
                        else displayValue = "No";

                        body.append(field.name, customLabel + displayValue);

                        // there are no checkbox bamm fields, so just set bammValue to blank
                        bammValue = "";
                    } else if (
                        field.customFieldType === "radio" ||
                        field.customFieldType === "select"
                    ) {
                        // checkbox list - show the checked item's label, or "None Selected" if none are checked
                        displayValue = "None Selected";

                        field.options.forEach(option => {
                            if (formValues[field.name] === option.value) {
                                displayValue = option.label;
                                bammValue = option.value;
                            }
                        });

                        body.append(field.name, customLabel + displayValue);
                    } else {
                        // text and textarea - show the entered value
                        body.append(
                            field.name,
                            customLabel + formValues[field.name],
                        );
                        displayValue = formValues[field.name];
                        bammValue = formValues[field.name];
                    }

                    // set fieldData
                    fieldData["name"] = field.name;
                    fieldData["label"] = field.label;
                    fieldData["value"] = displayValue;

                    if (field.bammName) {
                        fieldData["bammName"] = field.bammName;
                        fieldData["bammValue"] = bammValue;
                    } else {
                        fieldData["bammName"] = null;
                        fieldData["bammValue"] = "";
                    }

                    formData.push(fieldData);
                }
            }
        });

        body.append("formData", JSON.stringify(formData));

        let pageData = {
            firestoreId: firestoreId,
            pageTitle: pageTitle,
            bammUrl: bammUrl || "",
        };

        body.append("pageData", JSON.stringify(pageData));

        fetch(slug, {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body,
        }).catch(err => {
            setShowSavings(false);
            setNetlifyError(err.message);
        });
    };

    const calculateSavings = (doNetlifyIntegration = false) => {
        let loanAmount = 0;
        let totalCredit = 0;
        let lenderCreditAmount = 0;
        let realtorCommissionAmount = 0;
        let realtorCreditAmount = 0;
        let appraisalFeeCredit = 0;

        if (
            formValues.loanPurpose === "purchase" &&
            validator.current.check(purchasePrice, "required|currency") &&
            validator.current.check(downPayment, "required|currency")
        ) {
            loanAmount = purchasePrice - downPayment;
            if (allowDiscountedRealtorFee) {
                realtorCommissionAmount =
                    purchasePrice * realtorCommissionPercentage;
                realtorCreditAmount =
                    realtorCommissionAmount * realtorCreditPercentage;
            }
        } else if (
            formValues.loanPurpose === "refinance" &&
            validator.current.check(loanBalance, "required|currency") &&
            validator.current.check(cashoutAmount, "required|currency")
        ) {
            loanAmount = loanBalance + cashoutAmount;
            realtorCommissionAmount = 0;
            realtorCreditAmount = 0;
        }

        if (loanAmount !== 0) {
            lenderCreditAmount = loanAmount * lenderCreditPercentage;
            appraisalFeeCredit = !noAppraisalFeeCredit ? 450 : 0;
            totalCredit =
                lenderCreditAmount + realtorCreditAmount + appraisalFeeCredit;
        }

        setLoanAmount(loanAmount);
        setLenderCreditAmount(lenderCreditAmount);
        setRealtorCommissionAmount(realtorCommissionAmount);
        setRealtorCreditAmount(realtorCreditAmount);
        setAppraisalFeeCredit(appraisalFeeCredit);
        setTotalCredit(totalCredit);

        if (doNetlifyIntegration) integrateNetlify();
    };

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                customPhone: {
                    message: "The :attribute must be a valid phone number.",
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(
                            val,
                            /^(\+?0?1)?[(.-]?\d{3}[).-]?[\s.-]?\d{3}[\s.-]?\d{4}$/g,
                        );
                    },
                },
                purchasePrice: {
                    message: "The :attribute must be a valid amount.",
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(
                            val,
                            /^[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/g,
                        );
                    },
                },
                customEmail: {
                    message: "The :attribute must be a valid email address.",
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(
                            val, // from https://emailregex.com/
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                        );
                    },
                },
            },
            messages: {
                required: "This field is required",
            },
        }),
    );

    return (
        <section className={cls} style={style}>
            <div className={styles.inner}>
                <div className={styles.container}>
                    <div id="aboutCare" className={aboutCareClass}>
                        <div className={styles.row}>
                            <div className={styles.textContainer}>
                                <div
                                    id="about-care"
                                    className={styles.sectionHeader}>
                                    About The Program
                                </div>
                                <HTMLContent content={html} />
                                <div>
                                    {pageType === "corporate-care" && (
                                        <ul>
                                            {!noAppraisalFeeCredit && (
                                                <li>
                                                    Appraisal fee credited at
                                                    closing
                                                </li>
                                            )}
                                            <li>Highly competitive rates</li>
                                            <li>Complimentary credit review</li>
                                            <li>
                                                {d3.format(".2%")(
                                                    lenderCreditPercentage,
                                                )}{" "}
                                                credit to loan fees
                                            </li>
                                            {allowDiscountedRealtorFee && (
                                                <li>Discounted Realtor fees</li>
                                            )}
                                            <li>
                                                Superior service levels and
                                                quick closing
                                            </li>
                                        </ul>
                                    )}
                                    {pageType === "community-care" && (
                                        <ul>
                                            {!noAppraisalFeeCredit && (
                                                <li>
                                                    Appraisal fee credited at
                                                    closing
                                                </li>
                                            )}
                                            <li>Highly competitive rates</li>
                                            <li>Complimentary credit review</li>
                                            <li>
                                                {d3.format(".2%")(
                                                    lenderCreditPercentage,
                                                )}{" "}
                                                credit to loan fees
                                            </li>
                                            {realtorCommissionPercentage !==
                                                0 && (
                                                <li>Discounted Realtor fees</li>
                                            )}
                                            <li>
                                                Superior service levels and
                                                quick closing
                                            </li>
                                            <li>
                                                $250 Donation to participating
                                                501(c) (3)
                                            </li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.calculator}>
                        {!showSavings && (
                            <>
                                <div className={styles.calculatorPanelHeader}>
                                    {contactFormTitle}
                                    <div
                                        className={
                                            styles.calculatorPanelHeaderSmall
                                        }>
                                        {contactFormSubtitle}
                                    </div>
                                </div>
                                <div className={styles.calculatorPanelContent}>
                                    <CustomForm
                                        moduleIndex={moduleIndex}
                                        fields={fieldsWorkingCopy}
                                        hiddenFields={hiddenFields}
                                        isPreview={isPreview}
                                        formValues={formValues}
                                        predefinedInputs={predefinedInputs}
                                        validator={validator}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        handleFormSubmit={handleFormSubmit}
                                        submitButtonText="Calculate"
                                        lightText={true}
                                    />
                                </div>
                            </>
                        )}
                        {!!showSavings && (
                            <Savings
                                loanPurpose={formValues.loanPurpose}
                                loanAmount={loanAmount}
                                purchasePrice={purchasePrice}
                                downPayment={downPayment}
                                loanBalance={loanBalance}
                                cashoutAmount={cashoutAmount}
                                lenderCreditPercentage={lenderCreditPercentage}
                                realtorCommissionPercentage={
                                    realtorCommissionPercentage
                                }
                                realtorCreditPercentage={
                                    realtorCreditPercentage
                                }
                                lenderCreditAmount={lenderCreditAmount}
                                realtorCommissionAmount={
                                    realtorCommissionAmount
                                }
                                realtorCreditAmount={realtorCreditAmount}
                                appraisalFeeCredit={appraisalFeeCredit}
                                totalCredit={totalCredit}
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutCareModule;
