/* eslint-disable camelcase */
import { random } from "lodash";

import check_b1 from "./images/check_b1.png";
import check_b2 from "./images/check_b2.png";
import check_o1 from "./images/check_o1.png";
import check_o2 from "./images/check_o2.png";
import check_o3 from "./images/check_o3.png";
import gif_b1 from "./images/gif_b1.png";
import gif_b2 from "./images/gif_b2.png";
import gif_o1 from "./images/gif_o1.png";
import gif_o2 from "./images/gif_o2.png";
import gif_o3 from "./images/gif_o3.png";
import jpg_b1 from "./images/jpg_b1.png";
import jpg_b2 from "./images/jpg_b2.png";
import jpg_o1 from "./images/jpg_o1.png";
import jpg_o2 from "./images/jpg_o2.png";
import jpg_o3 from "./images/jpg_o3.png";
import pdf_b1 from "./images/pdf_b1.png";
import pdf_b2 from "./images/pdf_b2.png";
import pdf_o1 from "./images/pdf_o1.png";
import pdf_o2 from "./images/pdf_o2.png";
import pdf_o3 from "./images/pdf_o3.png";
import pic_b1 from "./images/pic_b1.png";
import pic_b2 from "./images/pic_b2.png";
import pic_o1 from "./images/pic_o1.png";
import pic_o2 from "./images/pic_o2.png";
import pic_o3 from "./images/pic_o3.png";
import png_b1 from "./images/png_b1.png";
import png_b2 from "./images/png_b2.png";
import png_o1 from "./images/png_o1.png";
import png_o2 from "./images/png_o2.png";
import png_o3 from "./images/png_o3.png";
import url_o3 from "./images/url_o3.png";
import url_o1 from "./images/url_o1.png";
import url_o2 from "./images/url_o2.png";
import url_b1 from "./images/url_b1.png";
import url_b2 from "./images/url_b2.png";
import zip_b1 from "./images/zip_b1.png";
import zip_b2 from "./images/zip_b2.png";
import zip_o1 from "./images/zip_o1.png";
import zip_o2 from "./images/zip_o2.png";
import zip_o3 from "./images/zip_o3.png";

import ppt from "./images/ppt.png";
import word from "./images/word.png";
import xls from "./images/xlsx.png";

const images = {
    check_b2,
    check_b1,
    check_o1,
    check_o2,
    check_o3,
    gif_b1,
    gif_b2,
    gif_o1,
    gif_o2,
    gif_o3,
    jpg_b1,
    jpg_b2,
    jpg_o1,
    jpg_o2,
    jpg_o3,
    pdf_b1,
    pdf_b2,
    pdf_o1,
    pdf_o2,
    pdf_o3,
    pic_b1,
    pic_b2,
    pic_o1,
    pic_o2,
    pic_o3,
    png_b1,
    png_b2,
    png_o1,
    png_o2,
    png_o3,
    url_o3,
    url_o1,
    url_o2,
    url_b1,
    url_b2,
    zip_b1,
    zip_b2,
    zip_o1,
    zip_o2,
    zip_o3,
    word,
    ppt,
    xls,
};

const backgrounds = ["b1", "b2", "o1", "o2", "o3"];

const doc = [
    "doc",
    "dot",
    "wbk",
    "docx",
    "docm",
    "dotx",
    "dotm",
    "docb",
    "doc",
    "dot",
    "wbk",
];

const Excel = [
    "xls",
    "xlt",
    "xlm",
    "xlsx",
    "xlsm",
    "xltx",
    "xltm",
    "xlsb",
    "xla",
    "xlam",
    "xll",
    "xlw",
];

const powerPoint = [
    "pptx",
    "pptm",
    "potx",
    "potm",
    "ppam",
    "ppsx",
    "ppsm",
    "sldx",
    "sldm",
    "ppt",
    "pot",
    "pps",
];

const exact = ["gif", "jpg", "pdf", "png", "url", "zip"];

const pics = ["tif", "tiff", "psd", "jpeg", "ai", "sketch"];

let lastBg = backgrounds[0];

const getBg = () => {
    let r = random(backgrounds.length - 1);
    let bg = backgrounds[r];
    if (bg === lastBg) {
        return getBg();
    }
    lastBg = bg;
    return bg;
};

/**
 *
 * @param {String} extension
 */
const getImage = extension => {
    let bg = getBg();
    let ext = extension.toLowerCase();
    if (doc.includes(ext)) return images.doc;
    if (Excel.includes(ext)) return images.xls;
    if (powerPoint.includes(ppt)) return images.ppt;
    if (exact.includes(ext)) {
        let key = `${ext}_${bg}`;
        return images[key];
    }
    if (pics.includes(ext)) {
        let key = `pic_${bg}`;
        return images[key];
    }
    // fallback to a checklist
    let key = `check_${bg}`;
    return images[key];
};

export default getImage;
