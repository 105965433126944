import React, { Component } from "react";
import Slide from "./Slide";
import SlideButton from "./SlideButton";
import styles from "./styles.module.less";

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0,
        };
    }
    goToSlide = n => {
        this.setState({ currentIndex: n });
    };
    render() {
        //get rid of blank images
        //make some buttons
        const { currentIndex } = this.state;
        const { imageList } = this.props;
        const Buttons = imageList
            .filter(Boolean)
            .map((i, index) => (
                <SlideButton
                    key={index}
                    index={index}
                    state={index === currentIndex ? "on" : "off"}
                    goToSlide={this.goToSlide}
                />
            ));
        return (
            <div className={styles.slider}>
                <Slide
                    photo={imageList[currentIndex]}
                    alt={imageList[currentIndex]}
                />
                {Buttons.length > 1 && (
                    <div className={styles.Buttons}>{Buttons}</div>
                )}
            </div>
        );
    }
}
export default Gallery;
