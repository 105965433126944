import React, { Component } from "react";
import Accent from "../Accent";
import styles from "./styles.module.less";
import { debounce } from "lodash";

export default class Facebook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dims: {
                small: {
                    w: 280,
                    h: 264,
                },
                large: {
                    w: 424,
                    h: 439,
                },
            },
            isLarge: false,
            ready: false,
        };
    }

    handleResize = e => {
        if (window.matchMedia("(min-width: 768px)").matches) {
            this.setState({
                isLarge: true,
                ready: true,
            });
        } else {
            this.setState({
                isLarge: false,
                ready: true,
            });
        }
    };

    debouncedHandler = debounce(e => {
        this.handleResize();
    }, 100);

    componentDidMount() {
        this.handleResize();
        window.addEventListener("resize", this.debouncedHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.debouncedHandler);
    }

    render() {
        const { facebookPageUrl } = this.props;
        const { ready } = this.state;
        const { w, h } = this.state.isLarge
            ? this.state.dims.large
            : this.state.dims.small;

        if (!facebookPageUrl) return null;

        return (
            <section className={styles.facebook} data-background="photo">
                <div className={styles.inner}>
                    <div className={styles.wrap}>
                        <div>
                            <h1
                                className={styles.title}
                                style={{ color: "white" }}>
                                Connect with me
                                <br />
                                on Facebook
                            </h1>
                            <Accent align="left" />
                        </div>
                    </div>
                    <div className={styles.frameWrapper}>
                        {ready && (
                            <iframe
                                title="FacebookPage"
                                src={`https://www.facebook.com/plugins/page.php?href=${encodeURIComponent(
                                    facebookPageUrl,
                                )}&tabs=timeline&width=${w}&height=${h}&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`}
                                width={w}
                                height={h}
                                style={{ border: "none", overflow: "hidden" }}
                                scrolling="no"
                                frameBorder={0}
                                allow="encrypted-media"
                            />
                        )}
                    </div>
                </div>
            </section>
        );
    }
}

Facebook.defaultProps = {
    facebookPageUrl: "https://www.facebook.com/facebook",
};
