import React, { Component } from "react";
import { Timeline } from "react-twitter-widgets";

import Accent from "../Accent";

import styles from "./styles.module.less";
const x = require("./xIcon.svg");
const TwitterBackground = require("./TwitterBackground.jpg");

class Twitter extends Component {
    state = {
        ready: false,
    };

    componentDidMount() {
        this.setState({ ready: true });
    }

    componentWillUnmount() {
        this.setState({ ready: false });
    }

    componentDidCatch(err, errinfo) {
        this.setState({ ready: false });
    }

    render() {
        const { url } = this.props;
        const { ready } = this.state;
        // if (url && ready) {
        return (
            <section
                className={styles.Twitter}
                style={{ backgroundImage: `url(${TwitterBackground})` }}
                data-background="photo">
                <div className={styles.inner}>
                    <div className={styles.container}>
                        <h1 className={styles.title}>
                            Follow me
                            <br />
                            on X
                        </h1>
                        <Accent align="left" />
                        <img
                            className={styles.twitterbird}
                            src={x}
                            alt="X logo"
                        />
                        {url && ready && (
                            <Timeline
                                dataSource={{
                                    sourceType: "url",
                                    url,
                                }}
                                options={{
                                    width: "100%",
                                    height: 475,
                                }}
                            />
                        )}
                    </div>
                </div>
            </section>
        );
        // }
        // return null;
    }
}

export default Twitter;
