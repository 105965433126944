import React from "react";
import ContactInfo from "../ContactInfo";
import TeamLink from "../TeamLink";
import Wordmark from "../Wordmark";
import StarRatings from "../../../StarRatings";
import Accent from "../../../Accent";
import RealtorView from "../RealtorView";

import { HTMLContent } from "../../../Content";

import Gallery from "../../../Gallery";
import PanelLo from "../../../PanelLo";
import SocialIcons from "../../../SocialIcons";
import ApplyNowLogIn from "../../../ApplyNowLogIn";

import withSurvey from "../../../withSurvey";

import styles from "./styles.module.less";

const FullView = ({
    templateKey,
    branch = {},
    slug,
    applyNow = "",
    applyNowScript = "",
    logIn = "",
    jobTitle,
    name,
    contactInfo = {},
    title,
    body,
    modules = [],
    team = {},
    licenseIds = [],
    socialIcons = [],
    gallery = [],
    stars,
    ratingsCount,
    isReviewInit,
    shouldDisplaySurvey,
    imageList,
    graphic,
    darkText,
    realtor,
    searchVisibility,
    overrideAddress = false,
    overrideAddressText = "",
    licensedInText = "",
    licenseLink,
}) => {
    const titleClass = darkText
        ? styles.title
        : `${styles.title} ${styles.titleLight}`;

    return (
        <div className={`${styles.root} ${styles.mobile}`}>
            <div className={styles.logoContainer}>
                <div className={styles.gallery}>
                    {imageList.length > 0 && imageList[0] && (
                        <Gallery
                            imageList={imageList.filter(Boolean)}
                            alt={name}
                        />
                    )}
                </div>
                <div className={styles.shortInfo}>
                    <div className={styles.shortInfoContent}>
                        <div className={styles.shortInfoWrapper}>
                            {templateKey === "branch" ? (
                                <>
                                    <h1 className={styles.title}>
                                        We are <b>{name}</b>
                                    </h1>
                                    <Accent align="left" />
                                </>
                            ) : templateKey === "team" ? (
                                <>
                                    <Wordmark
                                        graphic={graphic || null}
                                        name={name}
                                    />
                                </>
                            ) : (
                                <h1 className={styles.h2}>{name}</h1>
                            )}
                            <>
                                <div className={styles.shortInfoSecond}>
                                    <h2 className={styles.firstChild}>
                                        {jobTitle}
                                    </h2>
                                    <h3 className={styles.lastChild}>
                                        {licenseIds.map((l, i) => {
                                            return (
                                                <span
                                                    className={
                                                        styles.licenseeIds
                                                    }
                                                    key={l.license + l.id + i}>
                                                    {l.license}# {l.id}
                                                </span>
                                            );
                                        })}
                                    </h3>
                                    {licenseLink && (
                                        <div className={styles.licenseLink}>
                                            State Licenses:
                                            <a
                                                href={licenseLink}
                                                target="_blank"
                                                rel="noreferrer">
                                                Click Here
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </>
                        </div>

                        <div className={styles.starContainer}>
                            {isReviewInit && shouldDisplaySurvey && stars && (
                                <StarRatings
                                    modules={modules}
                                    stars={stars}
                                    ratingsCount={ratingsCount}
                                />
                            )}
                        </div>
                    </div>

                    {templateKey !== "branch" && (
                        <div className={styles.button}>
                            <ApplyNowLogIn
                                applyNow={applyNow}
                                applyNowScript={applyNowScript}
                                logIn={logIn}
                                slug={slug}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.infoContainer}>
                <div className={styles.moreInfo}>
                    <ContactInfo
                        {...contactInfo}
                        overrideAddress={overrideAddress}
                        overrideAddressText={overrideAddressText}
                    />
                    {licensedInText && (
                        <div style={{ whiteSpace: "break-spaces" }}>
                            Licensed in {licensedInText}
                        </div>
                    )}
                    <div className={styles.iconContainer}>
                        <SocialIcons {...socialIcons} darkText={darkText} />
                    </div>
                    <TeamLink team={team} />
                </div>
                <div className={styles.description}>
                    {title && <div className={titleClass}>{title}</div>}
                    <div className={styles.text}>
                        <HTMLContent content={body} />
                        {templateKey === "team" && !!branch && (
                            <div>
                                <p className={styles.branchLink}>
                                    <a href={branch.slug}>{branch.title}</a>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {realtor && (
                <div className={styles.realtor}>
                    <RealtorView
                        name={realtor.name}
                        jobTitle={!!realtor.title2 ? realtor.title2 : "Realtor"}
                        licenseIds={[
                            {
                                license: "License",
                                id: realtor.licenseNumber,
                            },
                        ]}
                        image={realtor.photoUrl}
                        logo={realtor.logoUrl}
                        contactInfo={realtor}
                        showCompactView={false}
                    />
                </div>
            )}
            {(templateKey === "loan-officer" || templateKey === "team") && (
                <PanelLo />
            )}
        </div>
    );
};

export default withSurvey(FullView);
