import React from "react";

import RealtorContactInfo from "../RealtorContactInfo";
import Gallery from "../../../Gallery";

import styles from "./styles.module.less";

const RealtorView = ({
    jobTitle,
    name,
    licenseIds = [],
    image,
    logo,
    contactInfo = {},
    showCompactView,
}) => {
    if (!name) return null;

    let shortInfoClass = styles.shortInfo;

    if (!showCompactView)
        shortInfoClass = `${styles.shortInfo} ${styles.shortInfoFullView}`;

    return (
        <div className={`${styles.root} ${styles.mobile}`}>
            <hr className={styles.realtorSeparator} />
            <div className={styles.logoContainer}>
                <div className={styles.gallery}>
                    {image && <Gallery imageList={[image]} alt={name} />}
                </div>
                <div className={shortInfoClass}>
                    <div className={styles.shortInfoContent}>
                        <div className={styles.shortInfoWrapper}>
                            <h1 className={styles.h2}>{name}</h1>
                            <div className={styles.shortInfoSecond}>
                                <h2 className={styles.firstChild}>
                                    {jobTitle}
                                </h2>
                                <h3 className={styles.lastChild}>
                                    {licenseIds.map((l, i) => {
                                        return (
                                            !!l.license && (
                                                <span
                                                    className={
                                                        styles.licenseeIds
                                                    }
                                                    key={l.license + l.id + i}>
                                                    {l.license}# {l.id}
                                                </span>
                                            )
                                        );
                                    })}
                                </h3>
                            </div>
                            <div className={styles.infoContainer}>
                                <div className={styles.moreInfo}>
                                    <RealtorContactInfo {...contactInfo} />
                                </div>
                            </div>
                        </div>

                        <div className={styles.ctaContainer}>
                            <img src={logo} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RealtorView;
