import React from "react";

import styles from "./styles.module.less";

interface TestimonialProps {
    text: string;
    name: string;
    image: string;
}
interface CareTestimonialsProps {
    items: Array<TestimonialProps>;
}
const CareTestimonials = (props: CareTestimonialsProps) => {
    let { items = [] } = props;

    if (!items.length) {
        return null;
    }

    return (
        <div>
            <section
                className={styles.widget}
                data-background="photo"
                id="care-testimonials">
                <div className={styles.inner}>
                    <h3 className={styles.sectionHeader}>TESTIMONIALS</h3>
                    {items.map((item, i) => (
                        <Testimonial {...item} key={i} />
                    ))}
                </div>
            </section>
        </div>
    );
};

const Testimonial = (props: TestimonialProps) => {
    let { text, name, image } = props;

    return (
        <div className={styles.Testimonial}>
            <div
                className={
                    !!image ? styles.TextContainer : styles.TextContainerNoImage
                }>
                <p className={styles.Text}>
                    <span style={{ fontStyle: "italic" }}>"{text}"</span> -{" "}
                    {name}
                </p>
            </div>
            {image && <img src={image} alt="" className={styles.Image} />}
        </div>
    );
};

export default CareTestimonials;
