import React, { Component } from "react";
import styles from "./styles.module.less";

import BEbug from "../../../images/bayequity_circle_mark.png";

class Marker extends Component {
    render() {
        const { title, handleClick } = this.props;
        return (
            <div onClick={handleClick} className={styles.container}>
                <img src={BEbug} alt={title} className={styles.bebug} />
            </div>
        );
    }
}
export default Marker;
