import React from "react";

import getImage from "./images";

const FileIcon = ({ extension = "", alt, className = "" }) => {
    let src = getImage(extension);
    return <img src={src} alt={alt} className={className} />;
};

export default FileIcon;
