import React, { PureComponent } from "react";
import styles from "./styles.module.less";

class CarouselComponent extends PureComponent {
    state = {
        activeIndex: 0,
    };

    slideIncrement = increment => () => {
        const { activeIndex } = this.state;
        const { componentsArr } = this.props;
        if (
            !componentsArr ||
            (activeIndex + increment === componentsArr.length ||
                activeIndex + increment < 0)
        ) {
            return;
        }
        this.setState({ activeIndex: activeIndex + increment });
    };

    slideToSlide = index => () => {
        this.setState({ activeIndex: index });
    };

    render() {
        const { activeIndex } = this.state;
        const { renderNavButtons, componentsArr } = this.props;
        const shouldRenderBack = renderNavButtons && activeIndex > 0;
        const shouldRenderForward =
            componentsArr &&
            renderNavButtons &&
            activeIndex < componentsArr.length - 1;
        return (
            <>
                {shouldRenderBack && (
                    <div className={styles.buttonBackContainer}>
                        <button
                            className={styles.buttonBack}
                            onClick={this.slideIncrement(-1)}
                        />
                    </div>
                )}
                {shouldRenderForward && (
                    <div className={styles.buttonForwardContainer}>
                        <button
                            className={styles.buttonForward}
                            onClick={this.slideIncrement(1)}
                        />
                    </div>
                )}
                <div className={styles.slidesContainer}>
                    {Array.isArray(componentsArr) &&
                        componentsArr.map((component, i) => (
                            <div
                                key={i}
                                className={`${styles.fade} ${
                                    i === activeIndex
                                        ? styles.activeSlide
                                        : styles.slide
                                }`}>
                                {component}
                            </div>
                        ))}
                </div>
                {Array.isArray(componentsArr) && componentsArr.length > 1 && (
                    <div className={styles.indicatorContainer}>
                        {Array.from(Array(componentsArr.length), (x, i) => (
                            <li
                                key={i}
                                onClick={this.slideToSlide(i)}
                                className={`${styles.carouselIndicator} ${
                                    activeIndex === i ? styles.active : ""
                                }`}
                            />
                        ))}
                    </div>
                )}
            </>
        );
    }
}

CarouselComponent.defaultProps = {
    componentsArr: [],
};

export default CarouselComponent;
