import firebase from "@firebase/app";
import "@firebase/auth";
import "@firebase/firestore";
import { once } from "lodash";

let config = {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
    dbEmail: process.env.GATSBY_FIREBASE_DBUSER_EMAIL,
    dbPassword: process.env.GATSBY_FIREBASE_DBUSER_PASSWORD,
};
if (process.env.GATSBY_BUILD === "develop") {
    config.apiKey = process.env.GATSBY_FIREBASE_API_KEY_DEV;
    config.authDomain = process.env.GATSBY_FIREBASE_AUTH_DOMAIN_DEV;
    config.databaseURL = process.env.GATSBY_FIREBASE_DATABASE_URL_DEV;
    config.projectId = process.env.GATSBY_FIREBASE_PROJECT_ID_DEV;
    config.storageBucket = process.env.GATSBY_FIREBASE_STORAGE_BUCKET_DEV;
    config.messagingSenderId =
        process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID_DEV;
}

/**
 * @return {Promise<UserCredential>}
 */
export const loginToDatabase = () => {
    return firebase
        .auth()
        .signInWithEmailAndPassword(config.dbEmail, config.dbPassword);
};

/**
 * @return {Promise<void>}
 */
export const logOutOfDatabase = () => {
    return firebase.auth().signOut();
};

export const initialize = once(() => firebase.initializeApp(config));
/**
 *
 * @param {Function} func function called with user object
 * @returns {Function} unsubscribe
 */
export const onAuthStateChanged = func =>
    firebase.auth().onAuthStateChanged(func);

// get a single instance of firestore db client
let db;
export const getFirestoreDb = () => {
    if (!db) {
        db = firebase.firestore();
    }
    return db;
};

export const timestamp = () => firebase.firestore.FieldValue.serverTimestamp();
export const timestampNow = () => firebase.firestore.Timestamp.now();
