import React from "react";
import ReactStarRatings from "react-star-ratings";

import styles from "./styles.module.less";

const StarRatings = ({ stars, modules, ratingsCount }) => {
    const experienceModule =
        modules.length && modules.find(mod => mod.type === "testimonials");
    const experienceUrl = experienceModule && experienceModule.social_page;
    let starsComponent = (
        <>
            <ReactStarRatings
                rating={parseFloat(stars)}
                starDimension="22px"
                starSpacing="2px"
                starRatedColor="rgba(255,138,0,1)"
            />
            <p
                className={`${styles.starsComment} ${
                    experienceUrl ? styles.link : ""
                }`}>
                based on {ratingsCount} reviews
            </p>
        </>
    );

    starsComponent = experienceUrl ? (
        <a href={experienceUrl} target="_blank" rel="noopener noreferrer">
            {starsComponent}
        </a>
    ) : (
        <>{starsComponent}</>
    );
    return <div className={styles.rating}>{starsComponent}</div>;
};

export default StarRatings;
