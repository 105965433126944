import React from "react";
import * as d3 from "d3";
import styles from "./styles.module.less";

interface CalculatorSavingsProps {
    loanPurpose: string;
    loanAmount: number;
    purchasePrice: number;
    downPayment: number;
    loanBalance: number;
    cashoutAmount: number;
    lenderCreditPercentage: number;
    realtorCommissionPercentage: number;
    realtorCreditPercentage: number;
    lenderCreditAmount: number;
    realtorCommissionAmount: number;
    realtorCreditAmount: number;
    appraisalFeeCredit: number;
    totalCredit: number;
}
const CalculatorSavings = (props: CalculatorSavingsProps) => {
    const {
        loanPurpose,
        loanAmount,
        purchasePrice,
        downPayment,
        loanBalance,
        cashoutAmount,
        lenderCreditPercentage,
        realtorCommissionPercentage,
        realtorCreditPercentage,
        lenderCreditAmount,
        realtorCommissionAmount,
        realtorCreditAmount,
        appraisalFeeCredit,
        totalCredit,
    } = props;
    return (
        <div>
            <div className={styles.calculatorPanelHeader}>
                Estimated benefit is{" "}
                <span>{d3.format("($,.0f")(totalCredit)}</span>
            </div>
            <div className={styles.calculatorPanelContent}>
                <div className={styles.benefitHeader}>
                    Loan Amount - {d3.format("($,.0f")(loanAmount)}
                </div>
                {loanPurpose === "purchase" && (
                    <div className={styles.benefitExplanation}>
                        <div>
                            <span>{d3.format("($,.0f")(purchasePrice)}</span> -{" "}
                            <span>{d3.format("($,.0f")(downPayment)}</span>
                        </div>
                    </div>
                )}
                {loanPurpose === "refinance" && (
                    <div className={styles.benefitExplanation}>
                        <div>
                            <span>{d3.format("($,.0f")(loanBalance)}</span> +{" "}
                            <span>{d3.format("($,.0f")(cashoutAmount)}</span>
                        </div>
                    </div>
                )}
                <div className={styles.benefitHeader}>
                    Credit to Loan Fees -{" "}
                    {d3.format("($,.0f")(lenderCreditAmount)}
                    <br />
                    <div className={styles.benefitExplanation}>
                        {d3.format(".2%")(lenderCreditPercentage)} of the Loan
                        Amount
                        <br />
                        {d3.format("($,.0f")(loanAmount)} x{" "}
                        {d3.format(".2%")(lenderCreditPercentage)} ={" "}
                        {d3.format("($,.0f")(lenderCreditAmount)} credit back
                    </div>
                </div>
                {loanPurpose === "purchase" && realtorCreditAmount !== 0 && (
                    <div className={styles.benefitHeader}>
                        Credit from Realtor -{" "}
                        {d3.format("($,.0f")(realtorCreditAmount)}
                        <div className={styles.benefitExplanation}>
                            {d3.format(".0%")(realtorCreditPercentage)} of the
                            Realtor Commission
                            <br />
                            {d3.format("($,.0f")(purchasePrice)} x{" "}
                            {d3.format(".2%")(realtorCommissionPercentage)} ={" "}
                            {d3.format("($,.0f")(realtorCommissionAmount)}{" "}
                            Realtor Commission
                            <br />
                            {d3.format("($,.0f")(
                                realtorCommissionAmount,
                            )} x {d3.format(".2%")(realtorCreditPercentage)} ={" "}
                            {d3.format("($,.0f")(realtorCreditAmount)} credit
                            back
                        </div>
                    </div>
                )}
                {appraisalFeeCredit !== 0 && (
                    <div className={styles.benefitHeader}>
                        Cost of Appraisal -{" "}
                        {d3.format("($,.0f")(appraisalFeeCredit)}
                    </div>
                )}
                <div className={styles.benefitDisclaimer}>
                    *Calculation is an estimate based on customary realtor
                    commissions and splits. Savings example above is for
                    education purposes only and subject to change based on
                    transaction specifics, including but not limited to:
                    purchase price, loan amount, etc.
                </div>
            </div>
            <div className={styles.calculatorPanelHeader}>
                Estimated benefit is{" "}
                <span>{d3.format("($,.0f")(totalCredit)}</span>
            </div>
        </div>
    );
};

export default CalculatorSavings;
