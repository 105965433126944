import React from "react";

import {
    returnStateAbbrev,
    formatPhoneAreaCode,
    formatPhoneNumber,
} from "../../../../lib/helpers";

import styles from "./styles.module.less";

const RealtorContactInfo = ({
    address,
    city,
    email,
    fax,
    phone,
    state,
    url,
    zip,
}) => {
    return (
        <div>
            {phone && (
                <div>
                    <div className={styles.first} aria-label="Telephone number">
                        P:
                    </div>
                    <div>
                        <a href={`tel:${formatPhoneNumber(phone)}`}>
                            {formatPhoneAreaCode(phone)}
                        </a>
                    </div>
                </div>
            )}
            {email && (
                <div>
                    <div className={styles.first} aria-label="Email address">
                        E:
                    </div>
                    <div>
                        <a href={`mailto:${email}`}>{email}</a>
                    </div>
                </div>
            )}
            {fax && (
                <div>
                    <div className={styles.first} aria-label="Fax number">
                        F:
                    </div>
                    <div>{formatPhoneAreaCode(fax)}</div>
                </div>
            )}
            {address && (
                <div>
                    <div className={styles.first} aria-label="Address">
                        A:
                    </div>
                    <div>{address}</div>
                </div>
            )}
            <div>
                <div className={styles.first} />
                <div>
                    {city}
                    {state ? ", " + returnStateAbbrev(state) : ""} {zip}
                </div>
            </div>
            {url && (
                <div>
                    <p className={styles.link}>
                        <a href={url}>{url}</a>
                    </p>
                </div>
            )}
        </div>
    );
};

export default RealtorContactInfo;
