import React, { Component } from "react";

import Accent from "../Accent";

import { whereToStart } from "../../lib/gtm";

import styles from "./styles.module.less";

import justlooking from "./../../images/justlooking.png";
import buyingagain from "./../../images/buyingagain.png";
import firsttime from "./../../images/firsttime.png";
import refinancing from "./../../images/refinancing.png";

const Panel = () => {
    return (
        <section className={styles.PanelLo}>
            <div className={styles.inner}>
                <div className={styles.panel}>
                    <h1 className={styles.title} style={{ fontSize: "25px" }}>
                        Not sure where to start?
                    </h1>
                    <Accent align="left" />
                    <div className={styles.content}>
                        <div className={styles.itemContainer}>
                            <div className={styles.item}>
                                <a href="/get-started" data-gtm={whereToStart}>
                                    <img
                                        src={justlooking}
                                        alt=""
                                        data-gtm={whereToStart}
                                    />
                                    <h2 data-gtm={whereToStart}>
                                        Just looking?
                                    </h2>
                                </a>
                            </div>
                            <div className={styles.item}>
                                <a
                                    href="/first-time-homebuyer"
                                    data-gtm={whereToStart}>
                                    <img
                                        src={firsttime}
                                        alt=""
                                        data-gtm={whereToStart}
                                    />
                                    <h2 data-gtm={whereToStart}>
                                        First-time homebuyer?
                                    </h2>
                                </a>
                            </div>
                            <div className={styles.item}>
                                <a
                                    href="/current-homeowner"
                                    data-gtm={whereToStart}>
                                    <img
                                        src={buyingagain}
                                        alt=""
                                        data-gtm={whereToStart}
                                    />
                                    <h2 data-gtm={whereToStart}>
                                        Buying again?
                                    </h2>
                                </a>
                            </div>
                            <div className={styles.item}>
                                <a
                                    href="/refinance-mortgage"
                                    data-gtm={whereToStart}>
                                    <img
                                        src={refinancing}
                                        alt=""
                                        data-gtm={whereToStart}
                                    />
                                    <h2 data-gtm={whereToStart}>
                                        Refinancing?
                                    </h2>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Panel;
